import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ReferredSolutionDto, ReferredSolutionsApi } from "@api";

import { NotificationService } from "~services/notification.service";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { WfDialog } from "~shared/dialog";

declare type ReviewAction = "approve" | "reject";

interface IReviewSolutionDialogData {
    solution: ReferredSolutionDto;
    fromMeeting: boolean;
}

@Component({
    selector: "app-review-solution-dialog",
    templateUrl: "./review-solution-dialog.component.html",
    styleUrls: ["./review-solution-dialog.component.scss"],
    host: {
        class: "wf-theme",
    },
})
export class ReviewSolutionDialogComponent {

    rejectButtonState: ButtonState;
    approveButtonState: ButtonState;
    readonly fromMeeting: boolean;

    readonly reviewNotesControl = new FormControl<string | null>(null, [Validators.maxLength(1000)]);
    readonly form = new FormGroup({
        reviewNotes: this.reviewNotesControl,
    });

    readonly solution: ReferredSolutionDto;

    constructor(
        private readonly referredSolutionsApi: ReferredSolutionsApi,
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<ReviewSolutionDialogComponent, ReferredSolutionDto>,
        @Inject(MAT_DIALOG_DATA) { solution, fromMeeting }: IReviewSolutionDialogData,
    ) {
        this.solution = solution;
        this.fromMeeting = fromMeeting;

        if (!this.fromMeeting) this.form.disable();
    }

    static open(dialog: WfDialog, solution: ReferredSolutionDto, fromMeeting: boolean = false) {
        return dialog.open<ReviewSolutionDialogComponent, IReviewSolutionDialogData, ReferredSolutionDto>(
            ReviewSolutionDialogComponent,
            {
                maxWidth: "none",
                // In the case the dialog is being used to view the solution, we want to auto-focus the header element.
                // This is because the first focusable element is often the action table heading, which is odd behavior.
                autoFocus: !fromMeeting ? "dialog" : ".dialog-footer textarea[name='reviewNotes']",
                data: { solution, fromMeeting },
                showCloseButton: false, // The close button is emitted by the homepage scaffold
            });
    }

    save = (type: ReviewAction) => {
        if (!this.fromMeeting || this.rejectButtonState || this.approveButtonState || !this.form.valid) return;
        this.setButtonState(type, "loading");
        const obs$ = type === "reject" ?
            this.referredSolutionsApi.rejectSolution(
                this.solution.company.id,
                this.solution.team.id,
                this.solution.id,
                {
                    reviewNotes: this.reviewNotesControl.value ?? undefined
                }
            ) :
            this.referredSolutionsApi.approveSolution(
                this.solution.company.id,
                this.solution.team.id,
                this.solution.id,
                {
                    reviewNotes: this.reviewNotesControl.value ?? undefined
                }
            );
        obs$.subscribe({
            next: solution => {
                this.setButtonState(type, "success");
                setTimeout(() => {
                    this.dialogRef.close(solution);
                }, 1000);
            },
            error: () => {
                this.setButtonState(type, "error");
                this.notificationService.errorUnexpected();
                setTimeout(() => {
                    this.setButtonState(type, undefined);
                }, 2000);
            },
        });
    };

    private setButtonState = (type: ReviewAction, buttonState: ButtonState) => {
        if (type === "reject") {
            this.rejectButtonState = buttonState;
            this.approveButtonState = undefined;
        } else {
            this.approveButtonState = buttonState;
            this.rejectButtonState = undefined;
        }
    };
}
