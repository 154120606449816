import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NumberRecordDetailDto, RequireNote, UpdateNumberResultDto, UpdateNumbersApi } from "@api";
import { EMPTY, Observable, of, ReplaySubject, Subscription } from "rxjs";
import { catchError, concatMap, debounceTime, filter, map, switchMap, tap } from "rxjs/operators";

import { TeamContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { NumberStateService } from "~services/state";
import { CommonFunctions, toFiscalQuarter } from "~shared/commonfunctions";
import { CaptureMethod } from "~shared/enums";
import { decimalValidator } from "~shared/util/custom-validators";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { getNumberTargetStatus, NumberStatus } from "~shared/util/number-helper";

import { NumberResultNotesDialogComponent } from "./number-result-notes-dialog/number-result-notes-dialog.component";

@Component({
    selector: "app-update-number-result",
    templateUrl: "./update-number-result.component.html",
    styleUrls: ["./update-number-result.component.scss"],
    host: {
        class: "wf-theme",
    }
})
export class UpdateNumberResultComponent implements OnInit, OnDestroy {

    get record(): NumberRecordDetailDto | undefined {
        return this.recordInternal;
    }

    @Input() set record(value: NumberRecordDetailDto | undefined) {
        const isSameNumber = value?.id === this.recordInternal?.id && value?.week === this.recordInternal?.week;
        this.recordInternal = value;
        if (!isSameNumber || value?.captureMethod === CaptureMethod.calculated || value?.dailyUpdateDefinition) {
            this.resultControl.reset(value?.result ?? null);
        } else if (!this.resultControl.dirty) {
            this.resultControl.setValue(value?.result ?? null);
        }
        this.updateResultDisabledState();
    }

    get disabled(): boolean {
        return this.disabledInternal;
    }

    @Input() set disabled(value: boolean) {
        this.disabledInternal = coerceBooleanProperty(value);
        this.updateResultDisabledState();
    }

    @Output() updated = new EventEmitter<NumberRecordDetailDto>();

    get isLoading() {
        return this.isLoadingInternal;
    }

    set isLoading(value: boolean) {
        if (value) {
            CommonFunctions.showLoader();
        } else {
            CommonFunctions.hideLoader();
        }
        this.isLoadingInternal = value;
    }

    get targetStatus(): NumberStatus {
        return getNumberTargetStatus(this.resultControl.value, this.record?.recordTarget);
    }

    get isCalculated(): boolean {
        return this.record?.captureMethod === CaptureMethod.calculated;
    }

    get calculationEnabled(): boolean {
        return this.teamContext.features.calculatedNumbersEnabled();
    }

    get dailyUpdatesEnabled() {
        return this.teamContext.features.dailyUpdatedNumbersEnabled();
    }

    get isAutomaticallyUpdated(): boolean {
        return this.record?.captureMethod === CaptureMethod.automatic;
    }

    get automaticRetrievalFailed(): boolean {
        return this.record?.automaticRetrievalFailed === true;
    }

    get statusNotesEnabled() {
        return this.teamContext.features.statusNotesEnabled();
    }

    get hasNote(): boolean {
        return !!this.record?.notes;
    }

    readonly resultControl = new FormControl<number | null>(null, [decimalValidator]);

    private recordInternal?: NumberRecordDetailDto;
    private isLoadingInternal = false;
    private disabledInternal = false;

    private resultSubject = new ReplaySubject<number | undefined>(1);
    private subscriptions: Subscription[] = [];

    private dialogShowing = false;

    constructor(
        private readonly updateNumbersApi: UpdateNumbersApi,
        private readonly numberStateService: NumberStateService,
        private readonly teamContext: TeamContext,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.resultSubject.pipe(
                tap(() => this.isLoading = true),
                debounceTime(50),
                concatMap(this.checkForNotes),
                // If we show the notes dialog, we may hide the loading indicator
                // Make sure at this point we are always setting the loading status.
                tap(() => this.isLoading = true),
                switchMap(this.updateNumber),
            ).subscribe(() => this.isLoading = false)
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    save = () => {
        const result = this.resultControl.value ?? undefined;
        const isResultValid = result === undefined || !isNaN(result) && this.resultControl.valid;
        if (!isResultValid) {
            this.resultControl.reset(this.record?.result ?? null);
            this.notificationService.error("numbers.update.invalidResult", undefined, undefined, true);
            return false;
        }
        if (result === (this.record?.result ?? undefined)) {
            return false;
        }
        this.resultSubject.next(result);
        return true;
    };

    displayAnyWarnings = () => {
        if (!this.isAutomaticallyUpdated || this.dialogShowing) return;
        this.dialogShowing = true;
        CommonFunctions.showConfirmDialog(this.dialog, {
            title: "Warning",
            description: "numbers.update.autoWarning",
            confirm: { title: "Continue" },
        }).afterClosed().subscribe(() => {
            this.dialogShowing = false;
        });
    };

    openNotesDialog = () => {
        if (!this.record || !this.statusNotesEnabled) return;
        NumberResultNotesDialogComponent.open(this.dialog, this.record, this.record.result)
            .afterClosed().pipe(
                filter(Boolean),
                tap(response => this.resultControl.setValue(response.result ?? null, { emitEvent: false })),
                tap(() => this.isLoading = true),
                switchMap(this.updateNumber),
            ).subscribe(() => this.isLoading = false);
    };

    private checkForNotes = (result: number | undefined): Observable<UpdateNumberResultDto> => {
        if (!this.record) return EMPTY;
        const record = this.record;
        const targetStatus = getNumberTargetStatus(result, record.recordTarget);
        const defaultResult: UpdateNumberResultDto = { result: result, notes: undefined };
        if (!this.statusNotesEnabled) return of(defaultResult);
        const requireNote = this.teamContext.features.noteEnforcementEnabled() ? record.requireNote : RequireNote.never;
        if (requireNote !== RequireNote.always && targetStatus !== "offtarget") {
            return of(defaultResult);
        }
        this.isLoading = false;
        return NumberResultNotesDialogComponent.open(this.dialog, record, result)
            .afterClosed().pipe(
                switchMap(response => {
                    if (!response) {
                        const noteRequired = requireNote === RequireNote.always ||
                            requireNote === RequireNote.whenOffTarget && targetStatus === "offtarget";
                        if (noteRequired) {
                            this.resultControl.reset(this.record?.result ?? null, { emitEvent: false });
                            return EMPTY;
                        }
                    }
                    return of(response);
                }),
                tap(response => {
                    if (!response || response.result === result) return;
                    this.resultControl.setValue(response.result ?? null, { emitEvent: false });
                }),
                map(response => response ?? defaultResult)
            );
    };

    private updateNumber = (dto: UpdateNumberResultDto): Observable<unknown> => {
        if (!this.record || !dto) return of(null);
        const { company, team } = getDelegatedItemCompanyTeam(this.record);
        return this.updateNumbersApi.updateResult(
            company.id,
            team.id,
            toFiscalQuarter({ financialYear: this.record.financialYear, quarter: this.record.planningPeriod }),
            this.record.week,
            this.record.id,
            dto,
        ).pipe(
            tap((record) => {
                if (this.record && this.record.id === record.id && this.record.week === record.week) {
                    this.record.result = record.result;
                    this.record.notes = record.notes;
                    this.record.resultToDate = record.resultToDate;
                }
                this.numberStateService.notifyUpdate(record);
                this.updated.emit(record);
                this.resultControl.reset(record.result, { emitEvent: false });
            }),
            catchError(() => {
                this.notificationService.errorUnexpected();
                this.resultControl.reset(this.record?.result);
                return of(null);
            })
        );
    };

    private updateResultDisabledState = () => {
        const value = this.recordInternal;
        if (!this.disabled && value && value.canEdit && value.captureMethod !== CaptureMethod.calculated && !value.dailyUpdateDefinition) {
            this.resultControl.enable();
        } else {
            this.resultControl.disable();
        }
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
    static ngAcceptInputType_disabled: BooleanInput;
}
