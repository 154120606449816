import { trigger } from "@angular/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ScoreTargetHierarchyDto, TeamPerformanceTargetsApi, UpdatePerformanceTargetDto } from "@api";
import { BehaviorSubject, catchError, combineLatest, filter, map, Observable, of, Subscription, switchMap, tap } from "rxjs";

import { IQuarter, PeriodRepository } from "~repositories";
import { TeamContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { CommonFunctions, toFiscalQuarter } from "~shared/commonfunctions";
import { defaultAnimationTiming, fadeInAnimationBuilder } from "~shared/util/animations";
import { integerValidator } from "~shared/util/custom-validators";
import { getScoreLevelNameKey } from "~shared/util/performance-shared";
import { getPreviousPeriod } from "~shared/util/period-helper";
import { valueAndChanges } from "~shared/util/util";

const flattenScoreHierarchy = (score: ScoreTargetHierarchyDto): { level: string; target?: number }[] => {
    const currentScore = { level: score.level, target: score.target };
    if (!score.children) return [currentScore];
    return [
        currentScore,
        ...score.children.map(flattenScoreHierarchy).flat(),
    ];
};

declare type TargetsForm = FormGroup<{
    [level: string]: FormControl<number | null>;
}>;

const hasNoTargets = (score: ScoreTargetHierarchyDto): boolean =>
    score.target == null && (!score.children || score.children.every(hasNoTargets));

interface ScoreTargets {
    [level: string]: number | null;
}

const combineTargets = (targetsObjects: ScoreTargets[]): ScoreTargets => {
    if (!targetsObjects.length) return {};
    const result: ScoreTargets = {};
    for (const targets of targetsObjects) {
        for (const [key, value] of Object.entries(targets)) {
            result[key] = value;
        }
    }
    return result;
};

const calculateWeightedTargets = (score: ScoreTargetHierarchyDto, targets: ScoreTargets): ScoreTargets => {
    let specifiedTarget = targets[score.level];
    // If we have no children, we just return the current level's target
    if (!score.children || !score.children.length) return { [score.level]: specifiedTarget };

    const result = combineTargets(score.children.map(child => calculateWeightedTargets(child, targets)));
    if (specifiedTarget == null) {
        // As no score is set explicitly, we need to calculate the weighted average for child scores.
        const childTargets = score.children
            .map(child => ({ weight: child.weighting, target: result[child.level] ?? null }))
            // Exclude any null targets
            .filter((child): child is { weight: number; target: number } => child.target !== null);

        const totalWeighting = childTargets.reduce((sum, child) => sum + child.weight, 0);
        if (totalWeighting > 0) {
            const totalWeightedSum = childTargets.reduce((sum, child) => sum + child.weight * child.target, 0);
            specifiedTarget = totalWeightedSum / totalWeighting;
        }
    }
    result[score.level] = specifiedTarget;
    return result;
};

const targetValidators = [Validators.min(0), Validators.max(100), integerValidator];

@Component({
    selector: "app-performance-targets",
    templateUrl: "./performance-targets.component.html",
    styleUrls: ["./performance-targets.component.scss"],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder(defaultAnimationTiming)),
    ],
    host: {
        class: "wf-theme wf-page",
    },
})
export class PerformanceTargetsComponent implements OnInit, OnDestroy {

    readonly companyId$: Observable<string>;

    readonly form = new FormGroup({
        targets: new FormGroup({}) as TargetsForm,
    });

    get hierarchy(): ScoreTargetHierarchyDto | null {
        return this.hierarchySubject.value;
    }

    get isLoading() {
        return this.isLoadingInternal;
    }

    set isLoading(value: boolean) {
        CommonFunctions.setLoader(value);
        if (this.form && value !== this.form.disabled) {
            if (value) {
                this.form.disable();
            } else {
                this.form.enable();
            }
        }
        this.isLoadingInternal = value;
    }

    get quarter(): IQuarter | null {
        return this.quarterSubject.value;
    }

    set quarter(value: IQuarter | null) {
        this.quarterSubject.next(value);
    }

    get canCopyPrevious(): boolean {
        // We can copy the previous quarter if none of the targets for this quarter are set.
        return !!this.quarter && !!this.hierarchy && hasNoTargets(this.hierarchy);
    }

    hasFailed = false;

    isUpdating = false;

    weightedTargets: ScoreTargets | null = null;

    readonly getScoreLevelNameKey = getScoreLevelNameKey;

    private isLoadingInternal = false;

    private readonly quarterSubject = new BehaviorSubject<IQuarter | null>(null);

    private readonly hierarchySubject = new BehaviorSubject<ScoreTargetHierarchyDto | null>(null);

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly targetsApi: TeamPerformanceTargetsApi,
        private readonly teamContext: TeamContext,
        private readonly periodRepository: PeriodRepository,
        private readonly notificationService: NotificationService,
    ) {
        this.companyId$ = this.teamContext.companyTeam$.pipe(
            filter(Boolean),
            map(ct => ct.company.id));
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest({
            companyTeam: this.teamContext.companyTeam$.pipe(filter(Boolean)),
            quarter: this.quarterSubject.pipe(filter(Boolean)),
        }).pipe(
            tap(this.initLoad),
            switchMap(({ companyTeam, quarter }) =>
                !companyTeam.team ? of(null) : this.getTargets(companyTeam.company.id, companyTeam.team.id, quarter).pipe(
                    catchError(this.loadFailed),
                )),
            tap(() => this.isLoading = false),
        ).subscribe(hierarchy => {
            this.hierarchySubject.next(hierarchy);
        }));
        this.subscriptions.add(this.hierarchySubject.subscribe(hierarchy => {
            this.form.reset();
            this.form.setControl("targets", this.buildFormGroup(hierarchy));
        }));
        this.subscriptions.add(combineLatest({
            hierarchy: this.hierarchySubject,
            targets: valueAndChanges(this.form).pipe(map(() => this.form.getRawValue().targets)),
        }).pipe(
            map(({ hierarchy, targets }) => !hierarchy ? null : calculateWeightedTargets(hierarchy, targets)),
        ).subscribe(weightedTargets => {
            this.weightedTargets = weightedTargets;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    refresh = () => {
        const quarter = this.quarterSubject.value;
        if (!quarter) return;
        this.quarterSubject.next(quarter);
    };

    save = () => {
        if (this.isUpdating || this.isLoading || !this.form || !this.form.valid || this.form.pristine) return;
        const companyTeam = this.teamContext.companyTeam();
        const quarter = this.quarterSubject.value;
        if (!companyTeam || !companyTeam.team || !quarter) return;

        const formValue = this.form.getRawValue().targets;
        const targets: UpdatePerformanceTargetDto[] =
            Object.keys(formValue).map(level => ({ level, target: formValue[level] ?? undefined }));

        if (!targets.length) return;

        this.isUpdating = true;
        this.targetsApi.updatePerformanceTargets(
            companyTeam.company.id,
            companyTeam.team.id,
            toFiscalQuarter(quarter),
            { targets },
        ).subscribe({
            next: () => {
                this.isUpdating = false;
                this.notificationService.success("performanceTargets.targetsUpdated", undefined, undefined, true);
                this.refresh();
            },
            error: () => {
                this.isUpdating = false;
                this.notificationService.errorUnexpected();
            },
        });
    };

    copyPrevious = () => {
        if (this.isUpdating || this.isLoading) return;

        const companyTeam = this.teamContext.companyTeam();
        const quarter = this.quarterSubject.value;
        if (!companyTeam || !companyTeam.team || !quarter) return;
        const { company, team } = companyTeam;

        this.isLoading = true;

        getPreviousPeriod(this.periodRepository, company.id, quarter).pipe(
            map(previousPeriod => ({ financialYear: previousPeriod.financialYear, quarter: previousPeriod.index })),
            switchMap(previousQuarter => this.getTargets(company.id, team.id, previousQuarter)),
        ).subscribe({
            next: previousTargets => {
                this.isLoading = false;
                const flatTargets = flattenScoreHierarchy(previousTargets);
                for (const [level, control] of Object.entries(this.form.controls.targets.controls)) {
                    const target = flatTargets.find(t => t.level === level);
                    if (!target) continue; // Don't update if not found.
                    control.setValue(target.target ?? null);
                    control.markAsDirty();
                }
            },
            error: () => {
                this.isLoading = false;
                this.notificationService.errorUnexpected();
            },
        });
    };

    asScore = (score: ScoreTargetHierarchyDto) => score;

    trackByLevel = (_: number, score: ScoreTargetHierarchyDto) => score.level;

    private initLoad = () => {
        this.isLoading = true;
        this.hasFailed = false;
    };

    private loadFailed = () => {
        this.notificationService.errorUnexpected();
        this.hasFailed = true;
        return of(null);
    };

    private getTargets = (companyId: string, teamId: string, quarter: IQuarter): Observable<ScoreTargetHierarchyDto> =>
        this.targetsApi.getPerformanceTargets(
            companyId,
            teamId,
            toFiscalQuarter(quarter));

    private buildFormGroup = (hierarchy: ScoreTargetHierarchyDto | null): TargetsForm => {
        if (!hierarchy) return new FormGroup({});
        const scores = flattenScoreHierarchy(hierarchy);
        const form: TargetsForm = new FormGroup({});
        for (const score of scores) {
            form.addControl(score.level,
                new FormControl<number | null>(score.target ?? null, targetValidators));
        }
        return form;
    };

}
