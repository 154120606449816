<ng-container *ngIf="entity && entity.delegation">
    <span class="delegation-icons" [attr.aria-label]="'delegationInfo.tooltip' | translate" 
        [wfTemplateTooltip]="popover" matTooltipClass="custom-tooltip" [class.delegation-icons-reversed]="reverse">
        <mat-icon color="primary" class="arrow">forward</mat-icon>
        <mat-icon color="primary" class="person">person</mat-icon>
    </span>
    <ng-template #popover>
        <dl class="delegation-info">
            <dt translate="delegationInfo.owner"></dt>
            <dd>{{ getUserName(entity.owner) }} ({{ entity.team.name }})</dd>

            <ng-container *ngIf="hasUpdater(entity)">
                <dt translate="delegationInfo.updater"></dt>
                <dd>{{ getUserName(entity.updater) }}</dd>
            </ng-container>

            <dt translate="delegationInfo.delegatedTo"></dt>
            <dd>{{ getUserName(entity.delegation.assignee) }} ({{ entity.delegation.team.name }})</dd>

            <ng-container *ngIf="hasResponsibility(entity)">
                <dt translate="delegationInfo.responsibility"></dt>
                <dd [translate]="getResponsibilityNameKey(entity.delegation.responsibility)"></dd>
            </ng-container>
        </dl>
    </ng-template>
</ng-container>