<button type="button" mat-icon-button [matMenuTriggerFor]="popupMenu">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #popupMenu="matMenu" class="wf-theme" xPosition="before">
    <button mat-menu-item [appDisable]="false" (click)="edit()" *ngIf="!disabled && isTeamOwned; else viewButton">
        <mat-icon color="primary">edit</mat-icon>
        <span>{{'Edit' | translate}}</span>
    </button>
    <ng-template #viewButton>
        <button mat-menu-item (click)="view()">
            <mat-icon color="primary">visibility</mat-icon>
            <span>{{'View' | translate}}</span>
        </button>
    </ng-template>
    <button mat-menu-item [appDisable]="disabled" [disabled]="disabled" isEditPermissionValid="true" (click)="delete()">
        <mat-icon color="warn">delete</mat-icon>
        <span>{{'Delete' | translate}}</span>
    </button>
</mat-menu>