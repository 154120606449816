<ng-container *ngIf="(hasAccess$ | async) && (watchlistsState$ | async); let state">
    <button mat-button type="button" [matMenuTriggerFor]="popupMenu"
        class="round-button" [color]="(isWatched$ | async) ? 'primary' : undefined"
        [matTooltip]="'watchlists.watchOptions' | translate" matTooltipClass="custom-tooltip"
        [disabled]="isLoading">
        <mat-icon>visibility</mat-icon>
        {{ 'watchlists.watchlist' | translate }}
    </button>
    <mat-menu #popupMenu class="wf-theme" xPosition="before">
        <div *matMenuContent class="wf-watch-menu">
            <ng-container *ngIf="state.personalEnabled">
                <div class="wf-watch-menu-title" *ngIf="state.teamEnabled" translate="watchlists.myWatchlists"></div>
                <button mat-menu-item *ngFor="let list of personalLists$ | async"
                    (click)="list.watchedControl.setValue(!list.watchedControl.value); $event.stopImmediatePropagation()">
                    <mat-slide-toggle [formControl]="list.watchedControl" color="primary" (click)="$event.stopImmediatePropagation()">
                        <span class="wf-list-name">{{ list.list.name }}</span>
                    </mat-slide-toggle>
                </button>
                <button mat-menu-item (click)="createList()">
                    <mat-icon color="primary">add</mat-icon>
                    {{ 'watchlists.createNewList' | translate }}
                </button>
            </ng-container>
            <ng-container *ngIf="state.teamEnabled">
                <div class="wf-watch-menu-title" *ngIf="state.personalEnabled" translate="watchlists.teamWatchlists"></div>
                <ng-container *ngIf="recentTeamLists$ | async; let recentLists">
                    <button mat-menu-item *ngFor="let list of recentLists"
                        (click)="list.watchedControl.setValue(!list.watchedControl.value); $event.stopImmediatePropagation()">
                        <mat-slide-toggle [formControl]="list.watchedControl" color="primary" (click)="$event.stopImmediatePropagation()">
                            <span class="wf-list-name">{{ list.list.name }}</span>
                        </mat-slide-toggle>
                    </button>
                    <p *ngIf="!recentLists.length" class="wf-watch-menu-no-lists" translate="watchlists.noRecentLists"></p>
                </ng-container>
                <button mat-menu-item (click)="openTeamLists()">
                    <mat-icon color="primary">more_horiz</mat-icon>
                    {{ 'watchlists.showMore' | translate }}
                </button>
            </ng-container>
        </div>
    </mat-menu>
</ng-container>
