<button type="button" mat-icon-button [matMenuTriggerFor]="popupMenu" [disabled]="disabled">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #popupMenu="matMenu" class="wf-theme" xPosition="before">
    <button mat-menu-item [appDisable]="false" (click)="edit()">
        <mat-icon color="primary">edit</mat-icon>
        <span>{{'Edit' | translate}}</span>
    </button>
    <button mat-menu-item [appDisable]="false" isEditPermissionValid="true" (click)="delete()">
        <mat-icon color="warn">delete</mat-icon>
        <span>{{'Delete' | translate}}</span>
    </button>
</mat-menu>