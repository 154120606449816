import { InjectionToken } from "@angular/core";
import { Stripe } from "@stripe/stripe-js";

export const DEFAULT_COUNTRY_LOCALE = "en";

export const STRIPE_INSTANCE = new InjectionToken<Stripe>("stripeInstance");

export const DEFAULT_PAGE_SIZE = 40;

export const PAGING_PIXELS_FROM_BOTTOM = 20;

export const NOTES_MAX_LENGTH = 10000;

export const LINK_PATTERN = "https?://.+";

export const DATE_LOCALE = "en-US";

export const DATE_FORMATS = [
    "dd/MM/yyyy",
    "MM/dd/yyyy",
    "d/M/yy",
    "M/d/yy",
    "d MMM yyyy",
    "MMM d yyyy",
    "yyyy-MM-dd",
];

export const DURATION_FORMAT = "HH:mm:ss";
