import { HttpErrorResponse } from "@angular/common/http";
import { booleanAttribute, Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TeamWatchlistsApi, UserWatchlistsApi, WatchlistItemReferenceDto } from "@api";
import { BehaviorSubject, catchError, combineLatest, EMPTY, filter, map, Observable, of, switchMap, tap, throwError } from "rxjs";

import { AccessService } from "~services/access.service";
import { TeamContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { EditUserWatchlistDialogComponent, TeamWatchlistPickerDialogComponent } from "~shared/dialogs";
import { EntityType } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { retryWithDelay } from "~shared/util/caching";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { isPersonalWatchlistsEnabled, isTeamWatchlistsEnabled } from "~shared/util/feature-helper";
import { shareReplayUntil, withRefresh } from "~shared/util/rx-operators";
import { buildWatchlistForms, isScopedUserList, ScopedWatchlistDto, subscribeToWatchedState, WatchlistForm } from "~shared/util/watch-form-shared";
import { buildItemReference as buildItemReferenceCore, ReferenceSource } from "~shared/util/watchlist-helper";

export declare type ItemType = "goal" | "number" | "report" | "action";

const toEntityType = (type: ItemType): EntityType => {
    switch (type) {
        case "action": return EntityType.action;
        case "goal": return EntityType.goal;
        case "number": return EntityType.number;
        case "report": return EntityType.report;
    }
};

const buildItemReference = (source: ReferenceSource, type: ItemType): WatchlistItemReferenceDto =>
    buildItemReferenceCore(source, toEntityType(type));

const LIST_ERROR_RETRY_DELAY_MS = 30000; // 30 seconds

type FeatureState = false | {
    personalEnabled: boolean;
    teamEnabled: boolean;
};

@Component({
    selector: "app-watch-item-button",
    templateUrl: "./watch-item-button.component.html",
    styleUrls: ["./watch-item-button.component.scss"]
})
export class WatchItemButtonComponent extends WithDestroy() {

    @Input() set type(value: ItemType | null) {
        this.typeSubject.next(value);
    }

    get type(): ItemType | null {
        return this.typeSubject.value;
    }

    @Input() set source(value: ReferenceSource | null) {
        this.sourceSubject.next(value);
    }

    get source(): ReferenceSource | null {
        return this.sourceSubject.value;
    }

    @Input({ transform: booleanAttribute }) set isPrivate(value: boolean) {
        this.isPrivateSubject.next(value);
    }

    get isPrivate(): boolean {
        return this.isPrivateSubject.value;
    }

    get isLoading(): boolean {
        return this.isLoadingPersonal || this.isLoadingTeam;
    }

    readonly personalLists$: Observable<WatchlistForm[]>;
    readonly recentTeamLists$: Observable<WatchlistForm[]>;
    readonly isWatched$: Observable<boolean>;

    readonly hasAccess$: Observable<boolean>;
    readonly watchlistsState$: Observable<FeatureState>;

    private isLoadingPersonal = false;
    private isLoadingTeam = false;

    private readonly typeSubject = new BehaviorSubject<ItemType | null>(null);
    private readonly sourceSubject = new BehaviorSubject<ReferenceSource | null>(null);
    private readonly isPrivateSubject = new BehaviorSubject<boolean>(false);
    private readonly refreshSubject = new BehaviorSubject<void>(undefined);

    constructor(
        private readonly userApi: UserWatchlistsApi,
        private readonly teamApi: TeamWatchlistsApi,
        private readonly teamContext: TeamContext,
        private readonly accessService: AccessService,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
    ) {
        super();

        this.watchlistsState$ = combineLatest({
            ct: this.teamContext.companyTeam$,
            isPrivate: this.isPrivateSubject,
        }).pipe(
            map(({ ct, isPrivate }) => {
                const personalEnabled = isPersonalWatchlistsEnabled(ct);
                const teamEnabled = !isPrivate && isTeamWatchlistsEnabled(ct);
                if (!personalEnabled && !teamEnabled) return false;
                return {
                    personalEnabled,
                    teamEnabled,
                };
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.hasAccess$ = this.sourceSubject.pipe(
            switchMap(source => {
                if (!source) return of(false);
                const { company, team } = "isDelegated" in source ? getDelegatedItemCompanyTeam(source) : source;
                return this.accessService.canAccessCompanyTeam(company.id, team.id);
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.personalLists$ = combineLatest({
            state: this.watchlistsState$,
            type: this.typeSubject,
            source: this.sourceSubject,
        }).pipe(
            tap(() => {
                this.isLoadingPersonal = true;
            }),
            withRefresh(this.refreshSubject),
            switchMap(({ state, type, source }) => !state || !state.personalEnabled || !type || !source ? of([]) :
                this.userApi.getListsForItem(
                    source.company.clientId,
                    buildItemReference(source, type),
                ).pipe(
                    catchError(this.handleGetListsError),
                    retryWithDelay(LIST_ERROR_RETRY_DELAY_MS),
                )),
            tap(() => this.isLoadingPersonal = false),
            map(buildWatchlistForms),
            switchMap(this.subscribeToWatchedState),
            shareReplayUntil(this.destroyed$),
        );

        this.recentTeamLists$ = combineLatest({
            state: this.watchlistsState$,
            type: this.typeSubject,
            source: this.sourceSubject,
        }).pipe(
            tap(() => {
                this.isLoadingTeam = true;
            }),
            withRefresh(this.refreshSubject),
            switchMap(({ state, type, source }) => !state || !state.teamEnabled || !type || !source ? of([]) :
                this.teamApi.getRecentListsForItem(
                    source.company.clientId,
                    buildItemReference(source, type),
                ).pipe(
                    catchError(this.handleGetListsError),
                    retryWithDelay(LIST_ERROR_RETRY_DELAY_MS),
                )),
            tap(() => this.isLoadingTeam = false),
            map(buildWatchlistForms),
            switchMap(this.subscribeToWatchedState),
            shareReplayUntil(this.destroyed$),
        );

        this.isWatched$ = combineLatest({
            personal: this.personalLists$,
            recentTeam: this.recentTeamLists$,
        }).pipe(
            map(lists => [...lists.personal, ...lists.recentTeam]),
            map(lists => lists.some(l => l.list.watched)),
            shareReplayUntil(this.destroyed$),
        );
    }

    createList = () => {
        const type = this.type;
        const source = this.source;
        if (!type || !source) return;
        EditUserWatchlistDialogComponent.openForAdd(this.dialog, source.company.clientId)
            .afterClosed().pipe(
                filter(Boolean),
                switchMap(list => this.userApi.watchItem(list.clientId, list.id, buildItemReference(source, type))),
            ).subscribe({
                next: () => this.refreshSubject.next(),
                error: () => {
                    this.notificationService.errorUnexpected();
                    this.refreshSubject.next();
                },
            });
    };

    openTeamLists = () => {
        const type = this.type;
        const source = this.source;
        if (!type || !source) return;
        TeamWatchlistPickerDialogComponent.open(this.dialog, source, toEntityType(type)).afterClosed().subscribe(
            () => this.refreshSubject.next(),
        );
    };

    private subscribeToWatchedState = (lists: WatchlistForm[]): Observable<WatchlistForm[]> =>
        subscribeToWatchedState(lists, (list, watched) => {
            const type = this.type;
            const source = this.source;
            if (!type || !source) return EMPTY;
            return this.setWatchedInternal(list, buildItemReference(source, type), watched).pipe(
                catchError(() => {
                    this.notificationService.errorUnexpected();
                    return of(list);
                }),
            );
        });

    private setWatchedInternal = (list: ScopedWatchlistDto, reference: WatchlistItemReferenceDto, watched: boolean):
        Observable<ScopedWatchlistDto> => {
        if (isScopedUserList(list)) {
            if (watched) {
                return this.userApi.watchItem(list.clientId, list.id, reference);
            } else {
                return this.userApi.unwatchItem(list.clientId, list.id, reference);
            }
        } else {
            if (watched) {
                return this.teamApi.watchItem(list.company.id, list.team.id, list.id, reference);
            } else {
                return this.teamApi.unwatchItem(list.company.id, list.team.id, list.id, reference);
            }
        }
    };

    private handleGetListsError = <TList extends ScopedWatchlistDto>(error: unknown): Observable<TList[]> => {
        if (error instanceof HttpErrorResponse) {
            switch (error.status) {
                case 400:
                case 403:
                case 404:
                case 409:
                    return of([]);
            }
        }
        return throwError(() => error);
    };

    /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
    static ngAcceptInputType_type: ItemType;
    static ngAcceptInputType_source: ReferenceSource;
    /* eslint-enable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
}
