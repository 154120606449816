<div class="list-container">
    <div class="list-header">
        <div class="header-top-row">
            <div class="header-add-menu" *ngIf="canAddFeedItems" @fadeIn>
                <app-add-item-header [(type)]="addItemType" [context]="newItemContext" (itemAdded)="itemAdded($event)"></app-add-item-header>
            </div>
            <div class="header-filter">
                <app-feed-partition-context-menu *ngIf="initialContext" [context]="initialContext"
                    [selectedContext]="selectedContext" (selectedContextChanged)="selectedContext = $event"></app-feed-partition-context-menu>
                <div class="header-filter-type-container" [appSmoothWidth]="isFilterSelected">
                    <mat-chip-set *ngIf="isFilterSelected">
                        <mat-chip (removed)="filterBy(null)" class="filter-chip">
                            <mat-icon>filter_list</mat-icon>
                            <ng-container [ngSwitch]="feedItemsDataSource.filter">
                                <ng-container *ngSwitchCase="'userGenerated'">
                                    {{'FeedsList.tooltips.filter.comments' | translate}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'systemGenerated'">
                                    {{'FeedsList.tooltips.filter.history' | translate}}
                                </ng-container>
                            </ng-container>
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-set>
                    <button mat-icon-button class="filter" *ngIf="!isFilterSelected" [attr.aria-pressed]="isFilterSelected"
                        [matMenuTriggerFor]="filterMenu" [matTooltip]="'FeedsList.tooltips.filter.filterBy' | translate" matTooltipClass="custom-tooltip">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <mat-menu #filterMenu="matMenu">
                        <button mat-menu-item type="button" (click)="filterBy('userGenerated')">
                            {{'FeedsList.tooltips.filter.comments' | translate}}
                        </button>
                        <button mat-menu-item type="button" (click)="filterBy('systemGenerated')">
                            {{'FeedsList.tooltips.filter.history' | translate}}
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="header-bottom-row" *ngIf="canAddFeedItems" @fadeExpand>
            <app-add-comment *ngIf="addItemType === 'comment'" [context]="newItemContext" [viewContext]="selectedContext"
                (itemAdded)="itemAdded($event)"></app-add-comment>
            <app-add-link *ngIf="addItemType === 'link'" [context]="newItemContext" [viewContext]="selectedContext"
                (itemAdded)="itemAdded($event)"></app-add-link>
        </div>
    </div>
    <div class="list-content" [hidden]="shouldHideFeedList"
        wfScrollListener [itemCount]="feedItemsDataSource.filteredData.length" (viewChange)="visibleRange.next($event)">
        <div class="content-container">
            <div class="list-line"></div>
            <ul>
                <app-feed-list-item *ngFor="let feedItem of feedItems$ | async" [feedItem]="feedItem"
                    [viewContext]="selectedContext" (changeContext)="selectedContext = $event"
                    (itemChanged)="refresh()">
                </app-feed-list-item>
            </ul>
        </div>
        <wf-table-scroll-footer [dataSource]="feedItemsDataSource"></wf-table-scroll-footer>
    </div>
    <div class="center-content" *ngIf="isLoadingInitialItems">
        <mat-spinner style="margin: auto;"></mat-spinner>
    </div>
    <div class="center-content" *ngIf="feedItemsDataSource.hasNoVisibleData">
        <img src="assets/images/empty-feed.svg" style="width: 25%" />
        <p>{{ 'FeedsList.listItems.noItems.empty' | translate }}</p>
        <p>{{ 'FeedsList.listItems.noItems.addNewItem' | translate }}</p>
        <ul>
            <li><mat-icon>add_comment</mat-icon>{{ 'FeedsList.listItems.noItems.leaveComment' | translate }}</li>
            <li><mat-icon>add_link</mat-icon>{{ 'FeedsList.listItems.noItems.shareLink' | translate }}</li>
            <li><mat-icon>upload_file</mat-icon>{{ 'FeedsList.listItems.noItems.attachFile' | translate }}</li>
        </ul>
    </div>
    <div class="center-content" *ngIf="feedItemsDataSource.loadFailed">
        <app-error-card (retry)="refresh()"></app-error-card>
    </div>
</div>