import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import * as moment from "moment";

const mustMatch = (value: string, valueToMatch: string): ValidationErrors | null => {
    if (!value) return null;
    if (value === valueToMatch) return null;
    return { mustMatch: true };
};

const greaterThanValue = (value: number, otherValue: number): ValidationErrors | null => {
    if (value === null || value === undefined ||
        otherValue === null || otherValue === undefined) {
        return null;
    }
    if (value >= otherValue) return null;
    return { greaterThan: true };
};

const greaterThanControlInternal =
    (control: AbstractControl, otherControl: AbstractControl | null | undefined): ValidationErrors | null => {
        if (!otherControl || otherControl.disabled) return null;
        return greaterThanValue(control.value, otherControl.value);
    };

const timeGreaterThan = (value: string, otherValue: string): ValidationErrors | null => {
    if (!value || !otherValue) return null;
    if (value > otherValue) return null;
    return { timeGreaterThan: true };
};

const dateGreaterThan = (value: moment.Moment, otherValue: moment.Moment): ValidationErrors | null => {
    if (!value || !otherValue) return null;
    return greaterThanValue(value.valueOf(), otherValue.valueOf());
};

/**
 * A validator that checks whether a control exactly matches a value.
 * Note: If the control has no value, this will not return a validation error.
 *
 * @param valueFunc A callback that will return the value that must be matched.
 */
export const mustMatchValue = (valueFunc: () => string): ValidatorFn =>
    (control: AbstractControl) => mustMatch(control.value, valueFunc());

/**
 * A validator that checks whether a control exactly matches the value of another control.
 * Note: If the control has no value, this will not return a validation error.
 *
 * @param formControlPath The path to the control that must be matched, relative to the parent control.
 */
export const mustMatchControl = (formControlPath: string): ValidatorFn =>
    (control: AbstractControl) => mustMatch(control.value, control.parent?.get(formControlPath)?.value);

/**
 * A validator that checks whether a time control is greater than another time control
 * Note: If the control has no value, this will not return a validation error.
 *
 * @param formControlPath The path to the control that must be matched, relative to the parent control.
 */
export const timeGreaterThanControl = (formControlPath: string): ValidatorFn =>
    (control: AbstractControl) => timeGreaterThan(control.value, control.parent?.get(formControlPath)?.value);

export const dateMin = (value: moment.Moment): ValidatorFn =>
    (control: AbstractControl) => dateGreaterThan(control.value, value);

export const dateGreaterThanControl = (formControlPath: string): ValidatorFn =>
    (control: AbstractControl) => dateGreaterThan(control.value, control.parent?.get(formControlPath)?.value);

export const greaterThanControl = (formControlPath: string): ValidatorFn =>
    (control: AbstractControl) => greaterThanControlInternal(control, control.parent?.get(formControlPath));

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 40;
export const PASSWORD_PATTERN = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,40}$";

const passwordValidators = [
    Validators.minLength(PASSWORD_MIN_LENGTH),
    Validators.maxLength(PASSWORD_MAX_LENGTH),
    Validators.pattern(PASSWORD_PATTERN),
];

export const passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    for (const validator of passwordValidators) {
        const result = validator(control);
        if (result) return result;
    }
    return null;
};

export const DECIMAL_PATTERN = /^-?(?=.{0,16}$)[0-9]+(\.[0-9]+)?$/;
export const INTEGER_PATTERN = /^-?\d{0,16}$/;

export const decimalValidator: ValidatorFn = Validators.pattern(DECIMAL_PATTERN);
export const integerValidator: ValidatorFn = Validators.pattern(INTEGER_PATTERN);
