<div [formGroup]="form">
    <section wf-expandable-section class="solution-information" [expanded]="isExpanded('solution')"
        [label]="'discussions.discussion' | translate">
        <mat-form-field *ngIf="isChallenge">
            <mat-label>{{ "discussions.solution.rootCause" | translate }}</mat-label>
            <textarea matInput formControlName="rootCause" cdkTextareaAutosize cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="5" cdkFocusInitial></textarea>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ "discussions.solution.discussionNotes" | translate }}</mat-label>
            <textarea matInput formControlName="discussionNotes" cdkTextareaAutosize cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="5" cdkFocusInitial></textarea>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ "discussions.solution.solution" | translate }}</mat-label>
            <textarea matInput formControlName="solution" cdkTextareaAutosize cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>
    </section>

    <section wf-expandable-section class="action-information" [expanded]="isActionsExpanded()"
        [disabled]="noActionRequiredControl.value">
        <div *wfExpandableSectionHeader class="add-action-header">
            <h4 [matBadge]="actionCount" matBadgeOverlap="false" matBadgeSize="small"
                translate="discussions.solution.actions"></h4>
            <ng-container *ngIf="!disabled && !noActionRequiredControl.value">
                <button type="button" mat-flat-button color="primary" class="add-button"
                    (click)="addAction(); $event.stopPropagation()">
                    <mat-icon>add</mat-icon>
                    {{ 'discussions.solution.add' | translate }}
                </button>
                <button type="button" mat-button class="round-button suggest-button" 
                    (click)="showSuggestedActions(); $event.stopPropagation()" *ngIf="suggestionsEnabled">
                    <mat-icon>tips_and_updates</mat-icon>
                    {{ 'discussions.solution.suggest.suggestActions' | translate }}
                </button>
            </ng-container>
            <mat-checkbox (click)="$event.stopPropagation()" formControlName="noActionRequired">
                {{ "discussions.solution.noActionRequired" | translate }}
            </mat-checkbox>
            <mat-error *ngIf="showActionError" @fadeIn>
                {{ "discussions.solution.noActionRequiredError" | translate }}
            </mat-error>
        </div>
        <div *wfExpandableSectionBody class="action-section">
            <table mat-table class="wf-table" [dataSource]="actionsDataSource" matSort matSortActive="dueDate"
                matSortDirection="asc" matSortDisableClear wfTableFocus>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
                        {{ "actions.description" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="wf-column-heading">
                        {{ getSolutionActionDescription(element) }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="owner">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ "actions.owner" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ getSolutionActionOwner(element) }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="dueDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ "actions.dueDate" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ getSolutionActionDueDate(element) | dateFormat}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="options">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "actions.options" | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index;" class="wf-theme">
                        <div *ngIf="!disabled; else viewAction" class="button-holder">
                            <button type="button" mat-icon-button color="primary" (click)="editSolutionAction(element)"
                                [matTooltip]="'Edit' | translate" matTooltipClass="custom-tooltip">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button type="button" mat-icon-button color="warn" (click)="deleteSolutionAction(i)"
                                [matTooltip]="'Delete' | translate" matTooltipClass="custom-tooltip">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                        <ng-template #viewAction>
                            <button type="button" mat-icon-button color="primary" (click)="viewSolutionAction(element)"
                                [matTooltip]="'View' | translate" matTooltipClass="custom-tooltip">
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </ng-template>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="actionsColumns"></tr>
                <tr mat-row *matRowDef="let element; let i = index; columns: actionsColumns"
                    [wfRowFocus]="element" [wfRowFocusIndex]="i"></tr>
                <tr *matNoDataRow>
                    <td colspan="actionsColumns.length" style="padding: 15px;">
                        {{ "discussions.solution.noActions" | translate }}
                    </td>
                </tr>
            </table>
        </div>
    </section>

    <section wf-expandable-section *ngIf="(approvalsSectionVisible$ | async) && (approvingTeams$ | async); let approvingTeams"
        class="approval-information" [expanded]="isExpanded('approval')"
        [label]="'discussions.solution.approvalDetails' | translate">
        <mat-form-field class="approving-team-field" *ngIf="!approvingTeamControl.disabled; else fakeApprovingTeam" floatLabel="always">
            <mat-label>{{ "discussions.solution.referForApproval" | translate }}</mat-label>
            <app-auto-select formControlName="approvingTeam" [options]="approvingTeams"
                [optionValueFunc]="getApprovingTeamReference"
                [optionDisplayFunc]="getApprovingTeamDisplay"
                [compareWith]="compareTeams">
                <ng-container *appAutoOption="let team">
                    {{ team ? team.name : ("discussions.solution.notRequired" | translate) }}
                </ng-container>
            </app-auto-select>
        </mat-form-field>
        <ng-template #fakeApprovingTeam>
            <mat-form-field class="approving-team-field" floatLabel="always">
                <mat-label>{{ "discussions.solution.referForApproval" | translate }}</mat-label>
                <input matInput type="text" disabled 
                    [value]="discussion?.solution?.approvingTeam ?
                        discussion?.solution?.approvingTeam?.name :
                        ('discussions.solution.notRequired' | translate)" />
            </mat-form-field>
        </ng-template>
        <div style="max-height: 200px; overflow-y: auto;" *ngIf="discussion?.solution?.approvalHistory">
            <div *ngFor="let historyItem of discussion?.solution?.approvalHistory"
                [class.approved]="isApproved(historyItem.operation)"
                [class.rejected]="isRejected(historyItem.operation)">
                <span class="approval-date">
                    {{ historyItem.dateTimeLocal | dateTimeFormat }}
                </span>
                <span class="approval-type">
                    {{ getSolutionApprovalActionTypeNameKey(historyItem.operation) | translate }}
                </span>
                <span class="approval-team-name">
                    {{ historyItem.team?.name ?? ("discussions.solution.deletedTeam" | translate) }}
                </span>
                <span *ngIf="historyItem.comment" class="approval-comment">
                    {{'discussions.solution.withTheseComments' | translate}} :
                    {{historyItem.comment}}
                </span>
            </div>
        </div>
    </section>
</div>