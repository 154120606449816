import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import {
    CurrentCompanyAndTeamDto, CurrentCompanyDto, CurrentTeamDto, FeatureCapStatusDto, FocusAreaDto, GetNumberDto, NumberSuggestionDto,
    PlanningSuggestionsApi, PlanNumbersApi
} from "@api";
import { BehaviorSubject, catchError, defer, delay, EMPTY, filter, Observable, of, Subject, Subscription, switchMap, tap } from "rxjs";

import { NumberCappedDialogComponent } from "~/app/plan-shared/dialogs";
import { environment } from "~/environments/environment";
import { NotificationService } from "~services/notification.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { withRefresh } from "~shared/util/rx-operators";
import { getNumberTypeNameKey } from "~shared/util/translation-helper";

import { EditNumberDialogComponent } from "../edit-number-dialog/edit-number-dialog.component";
import { exampleNumbers } from "./example-numbers";

export interface SuggestNumberDialogData extends CurrentCompanyAndTeamDto {
    financialYear: number;
    planningPeriod: number;
}

@Component({
    selector: "app-suggest-number-dialog",
    templateUrl: "./suggest-number-dialog.component.html",
    styleUrls: ["./suggest-number-dialog.component.scss"],
    host: {
        class: "wf-theme",
    },
})
export class SuggestNumberDialogComponent implements OnInit, OnDestroy {

    state: "focus" | "loading" | "suggestions" = "focus";

    readonly focusAreaControl = new FormControl<string | null>(null, [Validators.maxLength(1000)]);
    readonly form = new FormGroup({
        focusArea: this.focusAreaControl,
    });

    readonly dataSource = new MatTableDataSource<NumberSuggestionDto>();
    readonly displayedColumns = ["description", "type", "apply"];

    readonly getNumberTypeNameKey = getNumberTypeNameKey;

    private readonly company: CurrentCompanyDto;
    private readonly team: CurrentTeamDto;
    private readonly financialYear: number;
    private readonly planningPeriod: number;

    private readonly appliedSuggestions = new Map<NumberSuggestionDto, GetNumberDto>();

    private isCheckingCap = false;

    private readonly formDataSubject = new Subject<FocusAreaDto | null>();
    private readonly refreshSubject = new BehaviorSubject<void>(undefined);
    private readonly subscriptions = new Subscription();

    constructor(
        private readonly planningSuggestionsApi: PlanningSuggestionsApi,
        private readonly planNumbersApi: PlanNumbersApi,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<SuggestNumberDialogComponent, void>,
        @Inject(MAT_DIALOG_DATA) data: SuggestNumberDialogData,
    ) {
        this.company = data.company;
        this.team = data.team;

        this.financialYear = data.financialYear;
        this.planningPeriod = data.planningPeriod;
    }

    static open(dialog: MatDialog, data: SuggestNumberDialogData) {
        return dialog.open(SuggestNumberDialogComponent, {
            width: "800px",
            data,
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(this.formDataSubject.pipe(
            withRefresh(this.refreshSubject),
            tap(() => this.state = "loading"),
            switchMap(dto => this.getSuggestions(dto)),
            catchError(() => {
                this.dialogRef.close();
                this.notificationService.errorUnexpected();
                return EMPTY;
            }),
            switchMap(suggestions => {
                if (!suggestions.length) {
                    this.dialogRef.close();
                    this.notificationService.warning("numbers.suggest.noSuggestions", undefined, undefined, true);
                    return EMPTY;
                }
                return of(suggestions);
            }),
            tap(() => this.state = "suggestions"),
        ).subscribe(suggestions => this.dataSource.data = suggestions));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    submitFocusForm = () => {
        if (!this.form.valid) return;
        this.formDataSubject.next(this.getFormValue());
    };

    applySuggestion = (suggestion: NumberSuggestionDto) => {
        if (this.appliedSuggestions.has(suggestion)) return;

        this.checkCapStatus().pipe(
            switchMap(() =>
                EditNumberDialogComponent.openForAdd(this.dialog, {
                    company: this.company,
                    team: this.team,
                    financialYear: this.financialYear,
                    planningPeriod: this.planningPeriod,
                    input: suggestion,
                }).afterClosed()
            ),
            filter(Boolean),
        ).subscribe(number => {
            this.appliedSuggestions.set(suggestion, number);
        });
    };

    isApplied = (suggestion: NumberSuggestionDto) => this.appliedSuggestions.has(suggestion);

    private getFormValue = (): FocusAreaDto | null => {
        const focusArea = this.focusAreaControl.value;
        if (!focusArea) return null;
        return { focusArea };
    };

    private getSuggestions = (dto: FocusAreaDto | null): Observable<NumberSuggestionDto[]> => {
        if (environment.localSuggestions) {
            return of(exampleNumbers).pipe(
                delay(1000),
            );
        }
        if (dto) {
            return this.planningSuggestionsApi.getNumberSuggestionsForFocusArea(
                this.company.id,
                this.team.id,
                toFiscalQuarter({
                    financialYear: this.financialYear,
                    quarter: this.planningPeriod,
                }),
                dto);
        } else {
            return this.planningSuggestionsApi.getNumberSuggestions(
                this.company.id,
                this.team.id,
                toFiscalQuarter({
                    financialYear: this.financialYear,
                    quarter: this.planningPeriod,
                }));
        }
    };

    private checkCapStatus = (): Observable<void> =>
        defer(() => of(this.isCheckingCap)).pipe(
            filter(isCheckingCap => !isCheckingCap),
            tap(() => this.isCheckingCap = true),
            switchMap(() => this.getCapStatus()),
            tap(() => this.isCheckingCap = false),
            switchMap(this.handleCapStatus),
        );

    private getCapStatus = (): Observable<FeatureCapStatusDto> => {
        const numberCap = this.company.planTier.featureConstraints.numberCount;
        if (numberCap === null || numberCap === undefined) {
            return of({
                canAdd: true,
                actualCount: 0,
                cap: undefined
            });
        }

        return this.planNumbersApi.getCapStatus(
            this.company.id,
            this.team.id,
            toFiscalQuarter({ financialYear: this.financialYear, quarter: this.planningPeriod }),
        ).pipe(catchError(() => of({
            canAdd: true,
            actualCount: 0,
            cap: undefined
        })));
    };

    private handleCapStatus = (capStatus: FeatureCapStatusDto): Observable<void> => {
        if (capStatus.canAdd) return of(undefined);

        NumberCappedDialogComponent.open(this.dialog);
        return EMPTY;
    };

}
