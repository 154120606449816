import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { GetGoalDto, ListGoalsDto, PlanGoalsApi } from "@api";
import {
    BehaviorSubject, catchError, combineLatest, EMPTY, filter, map,
    Observable, of, startWith, Subscription, switchMap, tap
} from "rxjs";

import { IQuarter } from "~repositories";
import { TeamContext, UserContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { CommonFunctions, toFiscalQuarter } from "~shared/commonfunctions";
import { PlanningStatus } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { sortMultiple, sortString } from "~shared/util/sorters";

import { EditGoalDialogComponent } from "./edit-goal-dialog/edit-goal-dialog.component";
import { SuggestGoalDialogComponent } from "./suggest-goal-dialog/suggest-goal-dialog.component";

const sortGoals = sortMultiple<GetGoalDto>(
    sortString.ascending(g => g.heading),
    sortString.ascending(g => g.description),
);

@Component({
    selector: "app-goals",
    templateUrl: "./goals.component.html",
    styleUrls: ["./goals.component.scss"],
    host: {
        class: "wf-theme wf-page",
    },
})
export class GoalsComponent extends WithDestroy() implements OnInit, OnDestroy {

    readonly companyId$: Observable<string>;
    readonly quarter$: Observable<IQuarter | null>;

    readonly isCommittedControl = new FormControl(false, { nonNullable: true });

    get isLoading() {
        return this.isLoadingInternal;
    }

    set isLoading(value: boolean) {
        if (value) {
            CommonFunctions.showLoader();
        } else {
            CommonFunctions.hideLoader();
        }
        this.isLoadingInternal = value;
    }

    hasFailed = false;

    set quarter(value: IQuarter) {
        this.quarterSubject.next(value);
    }

    get useAucbgMenus() {
        return this.teamContext.settings.useAucbgMenus();
    }

    get suggestionsEnabled() {
        return this.teamContext.features.planningSuggestionsEnabled() ||
            this.userContext.isSuperAdmin() && this.teamContext.features.superAdminPlanningSuggestionsEnabled();
    }

    goals?: GetGoalDto[];

    private isLoadingInternal = false;

    private readonly quarterSubject = new BehaviorSubject<IQuarter | null>(null);
    private readonly dataSubject = new BehaviorSubject<ListGoalsDto | null>(null);

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly planGoalsApi: PlanGoalsApi,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
    ) {
        super();

        this.companyId$ = this.teamContext.companyTeam$.pipe(
            filter(Boolean),
            map(ct => ct.company.id));

        this.quarter$ = this.quarterSubject.pipe(shareReplayUntil(this.destroyed$));
    }

    ngOnInit(): void {
        this.subscriptions.add(this.dataSubject.subscribe(data => {
            if (data) {
                this.isCommittedControl.reset(data.planningStatus === PlanningStatus.locked, { emitEvent: false });
                this.goals = data.goals.sort(sortGoals);
            }
            if (!data || !data.goals.length) {
                this.isCommittedControl.disable({ emitEvent: false });
            } else {
                this.isCommittedControl.enable({ emitEvent: false });
            }
        }));
        this.subscriptions.add(combineLatest({
            quarter: this.quarterSubject,
            companyTeam: this.teamContext.companyTeam$,
        }).pipe(
            switchMap(({ quarter: qtr, companyTeam: ct }) => {
                if (!qtr || !ct || !ct.team) return EMPTY;
                this.initLoad();
                return this.planGoalsApi.listGoals(
                    ct.company.id,
                    ct.team.id,
                    toFiscalQuarter(qtr)
                ).pipe(
                    catchError(this.loadFailed),
                    tap(() => this.isLoading = false),
                    startWith(null),
                );
            }),
        ).subscribe(response => this.dataSubject.next(response)));
        this.subscriptions.add(this.isCommittedControl.valueChanges.subscribe(this.setCommittedStatus));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    refresh = () => {
        this.quarterSubject.next(this.quarterSubject.value);
    };

    addGoal = () => {
        const companyTeam = this.teamContext.companyTeam();
        const company = companyTeam?.company;
        const team = companyTeam?.team;
        const quarter = this.quarterSubject.value;
        if (!quarter || !company || !team) return;

        EditGoalDialogComponent.openForAdd(this.dialog, {
            company,
            team,
            financialYear: quarter.financialYear,
            planningPeriod: quarter.quarter,
        }).afterClosed().subscribe(res => res ? this.refresh() : null);
    };

    showSuggestions = () => {
        const companyTeam = this.teamContext.companyTeam();
        const company = companyTeam?.company;
        const team = companyTeam?.team;
        const quarter = this.quarterSubject.value;
        if (!quarter || !company || !team) return;
        SuggestGoalDialogComponent.open(this.dialog, {
            company,
            team,
            financialYear: quarter.financialYear,
            planningPeriod: quarter.quarter,
        }).afterClosed().subscribe(() => this.refresh());
    };

    private initLoad = () => {
        this.isLoading = true;
        this.hasFailed = false;
    };

    private loadFailed = () => {
        this.notificationService.errorUnexpected();
        this.hasFailed = true;
        return of(null);
    };

    private setCommittedStatus = (isCommitted: boolean) => {
        const currentData = this.dataSubject.value;
        const quarter = this.quarterSubject.value;
        const companyTeam = this.teamContext.companyTeam();
        if (this.isLoading || !currentData?.goals.length || !quarter || !companyTeam?.team) return;

        this.isLoading = true;
        this.planGoalsApi.updateStatus(
            companyTeam.company.id,
            companyTeam.team.id,
            toFiscalQuarter(quarter),
            {
                planningStatus: isCommitted ? PlanningStatus.locked : PlanningStatus.draft
            }
        ).subscribe({
            next: data => {
                this.dataSubject.next(data);
                this.notificationService.success("Quarter Status Changed");
                this.isLoading = false;
            },
            error: () => {
                this.notificationService.errorUnexpected();
                this.isLoading = false;
                this.dataSubject.next(this.dataSubject.value);
            },
        });
    };
}
