import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import {
    CurrentCompanyAndTeamDto, CurrentCompanyDto, CurrentTeamDto, FocusAreaDto, GetGoalDto, GoalSuggestionDto, PlanningSuggestionsApi
} from "@api";
import { BehaviorSubject, catchError, delay, EMPTY, filter, Observable, of, Subject, Subscription, switchMap, tap } from "rxjs";

import { environment } from "~/environments/environment";
import { NotificationService } from "~services/notification.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { withRefresh } from "~shared/util/rx-operators";

import { EditGoalDialogComponent } from "../edit-goal-dialog/edit-goal-dialog.component";
import { exampleGoals } from "./example-goals";

export interface SuggestGoalDialogData extends CurrentCompanyAndTeamDto {
    financialYear: number;
    planningPeriod: number;
}

@Component({
    selector: "app-suggest-goal-dialog",
    templateUrl: "./suggest-goal-dialog.component.html",
    styleUrls: ["./suggest-goal-dialog.component.scss"],
    host: {
        class: "wf-theme",
    },
})
export class SuggestGoalDialogComponent implements OnInit, OnDestroy {

    state: "focus" | "loading" | "suggestions" = "focus";

    readonly focusAreaControl = new FormControl<string | null>(null, [Validators.maxLength(1000)]);
    readonly form = new FormGroup({
        focusArea: this.focusAreaControl,
    });

    readonly dataSource = new MatTableDataSource<GoalSuggestionDto>();
    readonly displayedColumns = ["heading", "description", "apply"];

    private readonly company: CurrentCompanyDto;
    private readonly team: CurrentTeamDto;
    private readonly financialYear: number;
    private readonly planningPeriod: number;

    private readonly appliedSuggestions = new Map<GoalSuggestionDto, GetGoalDto>();

    private readonly formDataSubject = new Subject<FocusAreaDto | null>();
    private readonly refreshSubject = new BehaviorSubject<void>(undefined);
    private readonly subscriptions = new Subscription();

    constructor(
        private readonly planningSuggestionsApi: PlanningSuggestionsApi,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<SuggestGoalDialogComponent, void>,
        @Inject(MAT_DIALOG_DATA) data: SuggestGoalDialogData,
    ) {
        this.company = data.company;
        this.team = data.team;

        this.financialYear = data.financialYear;
        this.planningPeriod = data.planningPeriod;
    }

    static open(dialog: MatDialog, data: SuggestGoalDialogData) {
        return dialog.open(SuggestGoalDialogComponent, {
            width: "800px",
            data,
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(this.formDataSubject.pipe(
            withRefresh(this.refreshSubject),
            tap(() => this.state = "loading"),
            switchMap(dto => this.getSuggestions(dto)),
            catchError(() => {
                this.dialogRef.close();
                this.notificationService.errorUnexpected();
                return EMPTY;
            }),
            switchMap(suggestions => {
                if (!suggestions.length) {
                    this.dialogRef.close();
                    this.notificationService.warning("goals.suggest.noGoalSuggestions", undefined, undefined, true);
                    return EMPTY;
                }
                return of(suggestions);
            }),
            tap(() => this.state = "suggestions"),
        ).subscribe(suggestions => this.dataSource.data = suggestions));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    submitFocusForm = () => {
        if (!this.form.valid) return;
        this.formDataSubject.next(this.getFormValue());
    };

    applySuggestion = (suggestion: GoalSuggestionDto) => {
        if (this.isApplied(suggestion)) return;
        EditGoalDialogComponent.openForAdd(this.dialog, {
            company: this.company,
            team: this.team,
            financialYear: this.financialYear,
            planningPeriod: this.planningPeriod,
            input: suggestion,
        }).afterClosed().pipe(filter(Boolean)).subscribe(goal => {
            this.appliedSuggestions.set(suggestion, goal);
        });
    };

    isApplied = (suggestion: GoalSuggestionDto) => this.appliedSuggestions.has(suggestion);

    private getFormValue = (): FocusAreaDto | null => {
        const focusArea = this.focusAreaControl.value;
        if (!focusArea) return null;
        return { focusArea };
    };

    private getSuggestions = (dto: FocusAreaDto | null): Observable<GoalSuggestionDto[]> => {
        if (environment.localSuggestions) {
            return of(exampleGoals).pipe(
                delay(1000),
            );
        }
        if (dto) {
            return this.planningSuggestionsApi.getGoalSuggestionsForFocusArea(
                this.company.id,
                this.team.id,
                toFiscalQuarter({
                    financialYear: this.financialYear,
                    quarter: this.planningPeriod,
                }),
                dto);
        } else {
            return this.planningSuggestionsApi.getGoalSuggestions(
                this.company.id,
                this.team.id,
                toFiscalQuarter({
                    financialYear: this.financialYear,
                    quarter: this.planningPeriod,
                }));
        }
    };

}
