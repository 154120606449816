import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EntityReferenceDetailsDto, GetActionDto } from "@api";
import { BehaviorSubject, Observable, of, switchMap } from "rxjs";

import { AccessService } from "~services/access.service";
import { LinkedItemViewer } from "~services/linked-item-viewer.service";
import { EntityType } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";

export const isActionForSolution = (action: GetActionDto | undefined | null):
    action is GetActionDto & { origin: EntityReferenceDetailsDto } =>
    action?.origin?.type === EntityType.solution;

@Component({
    selector: "app-view-action-solution-button",
    templateUrl: "./view-action-solution-button.component.html",
    styleUrls: ["./view-action-solution-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "wf-theme",
    },
})
export class ViewActionSolutionButtonComponent extends WithDestroy() {

    @Input() set action(value: GetActionDto | null) {
        this.actionSubject.next(value);
    }

    get action() {
        return this.actionSubject.value;
    }

    readonly forSolution$: Observable<boolean>;

    private readonly actionSubject = new BehaviorSubject<GetActionDto | null>(null);

    constructor(
        private readonly accessService: AccessService,
        private readonly linkedItemViewer: LinkedItemViewer,
    ) {
        super();

        this.forSolution$ = this.actionSubject.pipe(
            switchMap(action => {
                if (!isActionForSolution(action)) return of(false);
                const origin = action.origin;
                if (!this.linkedItemViewer.canView(action.origin)) return of(false);
                return this.accessService.canAccessCompanyTeam(origin.company.id, origin.team.id);
            }),
            shareReplayUntil(this.destroyed$),
        );
    }

    viewSolution = () => {
        if (!isActionForSolution(this.action)) return;
        this.linkedItemViewer.view(this.action.origin);
    };
}
