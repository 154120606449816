import { Component, HostBinding, Input } from "@angular/core";
import { ManageUserApi, UserListDto } from "@api";
import * as moment from "moment";

import { NotificationService } from "~services/notification.service";
import { UserInvitationState } from "~shared/enums";

declare type InvitationButtonState = undefined | "sending" | "success" | "error";

@Component({
    selector: "app-user-invitation-button",
    templateUrl: "./user-invitation-button.component.html",
    styleUrls: ["./user-invitation-button.component.scss"],
    host: {
        class: "wf-theme"
    }
})
export class UserInvitationButtonComponent {

    @Input() user?: UserListDto;

    @HostBinding("class.show-button") get showButton(): boolean {
        return !!this.state;
    }

    state: InvitationButtonState;

    get isComplete(): boolean {
        return !!this.user && this.user.invitationState === UserInvitationState.complete;
    }

    get isExpired(): boolean {
        return !!this.user && this.user.invitationState === UserInvitationState.invitationSent &&
            !!this.user.invitationExpiryDateUtc && moment(this.user.invitationExpiryDateUtc).isBefore(moment());
    }

    get isSent() {
        return !!this.user && this.user.invitationState === UserInvitationState.invitationSent &&
            (!this.user.invitationExpiryDateUtc || !moment(this.user.invitationExpiryDateUtc).isBefore(moment()));
    }

    get isNotInvited() {
        // We show "not invited" if the user is not set, but this should never occur.
        return !this.user || this.user.invitationState === UserInvitationState.notInvited;
    }

    constructor(
        private readonly manageUserApi: ManageUserApi,
        private readonly notificationService: NotificationService
    ) { }

    sendInvitation = () => {
        if (this.state || !this.user || this.isComplete) return;

        this.state = "sending";

        this.manageUserApi.sendInvitation(this.user.userId).subscribe({
            next: () => {
                this.setStateWithReset("success");
                this.notificationService.success("invitationButton.emailSent", undefined, undefined, true);
                if (this.user) {
                    this.user.invitationExpiryDateUtc = undefined; // No expiry means it will display as "Invitation Sent"
                    this.user.invitationState = UserInvitationState.invitationSent;
                }
            },
            error: () => {
                this.setStateWithReset("error");
                this.notificationService.errorUnexpected();
            },
        });

    };

    private setStateWithReset = (state: InvitationButtonState) => {
        this.state = state;
        setTimeout(() => this.state = undefined, 2000);
    };
}
