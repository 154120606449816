import { IsActiveMatchOptions } from "@angular/router";
import { CurrentCompanyAndTeamDto, CurrentUserDto } from "@api";

import { Profile, Role } from "~shared/enums";
import { isAnnualPlanningEnabled, isAnnualPlanningTeased, isApprovalsEnabled, isCompanyPerformanceEnabled, isCompanyPerformanceTeased, isCrossTeamFeaturesEnabled, isEnterprisePerformanceEnabled, isLeagueEnabled, isNewsEnabled, isPerformanceTargetsEnabled, isPersonalWatchlistsEnabled, isReportsEnabled, isTeamAnalyticsEnabled, isTeamWatchlistsEnabled, isUserAnalyticsEnabled } from "~shared/util/feature-helper";
import { getProfileRoles } from "~shared/util/profile-helper";

import { IMenuItem } from "../menu-list-item/menu-list-item.component";

const canAccessPage = (user: CurrentUserDto | null, page: string): boolean => {
    if (!user) return false;

    // If the user is not of "User" role, they can access the feature
    if (user.roleName as Role !== Role.user) return true;

    return user.pagePermissions.some(p => p.name === page);
};

const isInProfile = (profile: Profile, role: Role | null): boolean => {
    const roles = getProfileRoles(profile);
    return !!role && roles.includes(role);
};

const CHILD_ROUTE_MATCH: IsActiveMatchOptions = { paths: "subset", queryParams: "subset", fragment: "ignored", matrixParams: "ignored" };

export const buildMenuItems = (user: CurrentUserDto | null, companyTeam: CurrentCompanyAndTeamDto | null): IMenuItem[] => {
    const company = companyTeam?.company ?? null;
    const team = companyTeam?.team ?? null;

    const role = (user?.roleName ?? null) as Role | null;
    const isFullUser = isInProfile(Profile.fullUser, role);
    const isAdmin = isInProfile(Profile.admin, role);
    const isEnterpriseAdmin = isInProfile(Profile.enterpriseAdmin, role);
    const isHumanResources = isInProfile(Profile.humanResources, role);
    const isPartnerAdmin = isInProfile(Profile.partnerAdmin, role);

    const teamRoutePrefix = company && team ? ["/company", company.id, "team", team.id] : ["/"];

    const useAucbgMenus = company?.settings.useAucbgMenus ?? false;

    const homeSection: IMenuItem[] = [{
        id: "dashboard",
        route: [...teamRoutePrefix, "dashboard"],
        type: "link",
        name: "Home",
        icon: "home",
        show: true
    },
    {
        id: "update",
        route: [...teamRoutePrefix, "update"],
        type: "link",
        name: "updatePage.update",
        icon: "update",
        routeMatchOptions: CHILD_ROUTE_MATCH,
        show: isFullUser && canAccessPage(user, "update"),
    },
    {
        id: "discussions",
        route: [...teamRoutePrefix, "discussions"],
        type: "link",
        name: "discussions.discussions",
        icon: "connect_without_contact",
        show: isFullUser && canAccessPage(user, "discussions")
    },
    {
        id: "approvals",
        route: [...teamRoutePrefix, "discussions", "approvals"],
        type: "link",
        name: "discussions.approvals.approvals",
        icon: "thumb_up",
        show: isApprovalsEnabled(company) && !!team?.isApprover &&
            isFullUser && canAccessPage(user, "approvals")
    },
    {
        id: "news",
        route: [...teamRoutePrefix, "news"],
        type: "link",
        name: "news.news",
        icon: "live_tv",
        show: isNewsEnabled(company) &&
            isFullUser && canAccessPage(user, "news")
    },
    {
        id: "actions",
        route: [...teamRoutePrefix, "actions"],
        type: "link",
        name: "actions.actions",
        icon: "playlist_add_check",
        show: isFullUser && canAccessPage(user, "actions"),
    },
    {
        id: "new-meeting",
        route: [...teamRoutePrefix, "meeting", "new"],
        name: "New Meeting",
        type: "link",
        icon: "meeting_room",
        show: isFullUser && canAccessPage(user, "newmeeting")
    }];
    const watchlistSection: IMenuItem[] = [
        {
            id: "my-watchlists",
            name: "watchlists.myWatchlists",
            route: [...teamRoutePrefix, "watchlists", "personal"],
            type: "link",
            icon: "visibility",
            routeMatchOptions: CHILD_ROUTE_MATCH,
            show: isPersonalWatchlistsEnabled(company)
        },
        {
            id: "team-watchlists",
            name: "watchlists.teamWatchlists",
            route: [...teamRoutePrefix, "watchlists", "team"],
            type: "link",
            icon: "visibility",
            routeMatchOptions: CHILD_ROUTE_MATCH,
            show: isTeamWatchlistsEnabled(company)
        },
    ];
    const teamPerformanceSection: IMenuItem[] = [
        {
            id: "leagues",
            route: [...teamRoutePrefix, "league"],
            name: "leagues.leagues",
            type: "link",
            icon: "emoji_events",
            show: isLeagueEnabled(company) &&
                isFullUser && canAccessPage(user, "dashboard")
        },
        {
            id: "team-performance",
            route: [...teamRoutePrefix, "performance", "team"],
            name: "performance.teamPerformance",
            type: "link",
            icon: "group_add",
            show: isFullUser && canAccessPage(user, "dashboard")
        },
        {
            id: "company-performance",
            route: [...teamRoutePrefix, "performance", "company"],
            name: "performance.companyPerformance",
            type: "link",
            icon: "account_balance",
            show: isCompanyPerformanceEnabled(company) &&
                isFullUser && canAccessPage(user, "companydashboard")
        },
        {
            id: "company-performance",
            route: [...teamRoutePrefix, "performance", "company-upgrade"],
            name: "performance.companyPerformance",
            type: "link",
            icon: "account_balance",
            teaser: true,
            show: isCompanyPerformanceTeased(company) &&
                isFullUser && canAccessPage(user, "companydashboard")
        },
        {
            id: "enterprise-performance",
            route: [...teamRoutePrefix, "performance", "enterprise"],
            name: "performance.enterprisePerformance",
            type: "link",
            icon: "location_city",
            show: isEnterprisePerformanceEnabled(company) &&
                isEnterpriseAdmin && canAccessPage(user, "enterprisedashboard")
        },
        {
            id: "team-analytics",
            route: [...teamRoutePrefix, "performance", "team-analytics"],
            name: "performance.teamAnalytics",
            type: "link",
            icon: "insights", // other options may be "query_stats" or "analytics"
            routeMatchOptions: CHILD_ROUTE_MATCH,
            show: isTeamAnalyticsEnabled(company) &&
                isHumanResources && canAccessPage(user, "dashboard"),
        },
        {
            id: "user-analytics",
            route: [...teamRoutePrefix, "performance", "user-analytics"],
            name: "performance.userAnalytics",
            type: "link",
            icon: "query_stats",
            routeMatchOptions: CHILD_ROUTE_MATCH,
            show: isUserAnalyticsEnabled(company) &&
                isHumanResources && canAccessPage(user, "dashboard"),
        },
        {
            id: "meeting-archive",
            route: [...teamRoutePrefix, "meeting", "archive"],
            type: "link",
            name: "meetingArchive.title",
            icon: "archive",
            show: isFullUser && canAccessPage(user, "meetingarchive")
        },
        {
            id: "annual-planning",
            route: [...teamRoutePrefix, "pages", "annual-planning-upgrade"],
            type: "link",
            name: "Annual Planning",
            icon: "table_view",
            teaser: true,
            show: isAnnualPlanningTeased(company) &&
                isFullUser && canAccessPage(user, "annualplanning")
        },
        {
            id: "annual-planning",
            type: "menu",
            name: "Annual Planning",
            icon: "table_view",
            show: isAnnualPlanningEnabled(company) &&
                isFullUser && canAccessPage(user, "annualplanning"),
            children: [
                {
                    id: "annual-planning-mission",
                    name: "Mission",
                    type: "link",
                    icon: "start_border",
                    route: [...teamRoutePrefix, "pages", "mission"],
                    show: useAucbgMenus,
                },
                {
                    id: "annual-planning-values",
                    name: "Values",
                    type: "link",
                    icon: "favorite_border",
                    route: [...teamRoutePrefix, "pages", "values"],
                    show: useAucbgMenus,
                },
                {
                    id: "annual-planning-projections",
                    name: useAucbgMenus ? "Vision Elements" : "Projections",
                    type: "link",
                    icon: "batch_prediction",
                    route: [...teamRoutePrefix, "pages", "plan"],
                    show: true,
                },
                {
                    id: "annual-planning-vision",
                    name: "Vision Statement",
                    type: "link",
                    icon: "record_voice_over",
                    route: [...teamRoutePrefix, "pages", "vision-new"],
                    show: useAucbgMenus,
                },
                {
                    id: "annual-planning-visions-csf",
                    name: "Visions CSF",
                    type: "link",
                    icon: "list",
                    route: [...teamRoutePrefix, "pages", "visions-csf"],
                    show: useAucbgMenus,
                },
                {
                    id: "annual-planning-staff",
                    name: "Staff Resources",
                    type: "link",
                    icon: "perm_identity",
                    route: [...teamRoutePrefix, "pages", "hirePlan"],
                    show: useAucbgMenus,
                },
                {
                    id: "annual-planning-resources",
                    name: "Resources (Non Staff)",
                    type: "link",
                    icon: "device_hub",
                    route: [...teamRoutePrefix, "pages", "resourcesnonstaff"],
                    show: useAucbgMenus,
                },
                {
                    id: "annual-planning-measures-of-success",
                    name: "Measures of Success",
                    type: "link",
                    icon: "thumb_up",
                    route: [...teamRoutePrefix, "pages", "measures-of-success"],
                    show: useAucbgMenus,
                },
                {
                    id: "annual-planning-foundation-principles",
                    name: "Foundation Principles",
                    type: "link",
                    icon: "start_border",
                    route: [...teamRoutePrefix, "pages", "vision"],
                    show: !useAucbgMenus,
                },
                {
                    id: "annual-planning-mission",
                    name: useAucbgMenus ? "Goals and Strategies" : "Business Goals",
                    type: "link",
                    icon: "track_changes",
                    route: [...teamRoutePrefix, "annual-planning", "goals"],
                    show: true,
                },
                {
                    id: "annual-planning-business-plan",
                    name: useAucbgMenus ? "Planning Memorandum" : "Business Plan",
                    type: "link",
                    icon: "memory",
                    route: [...teamRoutePrefix, "pages", "plansummary"],
                    show: true,
                },
                {
                    id: "annual-planning-dates",
                    name: "Dates",
                    type: "link",
                    icon: "date_range",
                    route: [...teamRoutePrefix, "pages", "dates"],
                    show: true,
                },
            ],
        },
        {
            id: "quarterly-planning",
            type: "menu",
            name: "Quarterly Planning",
            show: isFullUser && canAccessPage(user, "quarterlyplanning"),
            icon: "date_range",
            children: [
                {
                    id: "quarterly-planning-goals",
                    name: useAucbgMenus ? "goals.quarterlyTargets" : "goals.goals",
                    type: "link",
                    icon: "task_alt",
                    route: [...teamRoutePrefix, "quarterly-planning", "goals"],
                    show: true,
                },

                {
                    id: "quarterly-planning-numbers",
                    name: "numbers.numbers",
                    type: "link",
                    icon: "format_list_numbered",
                    route: [...teamRoutePrefix, "quarterly-planning", "numbers"],
                    show: true,
                },

                {
                    id: "quarterly-planning-reports",
                    name: "reports.reports",
                    type: "link",
                    icon: "article",
                    route: [...teamRoutePrefix, "quarterly-planning", "reports"],
                    show: isReportsEnabled(company),
                },

                {
                    id: "quarterly-planning-performance-targets",
                    name: "performanceTargets.title",
                    type: "link",
                    icon: "adjust",
                    route: [...teamRoutePrefix, "quarterly-planning", "performance-targets"],
                    show: isPerformanceTargetsEnabled(company)
                },
                {
                    id: "quarterly-planning-team-execution",
                    name: "executionPlanReportScreen.teamExecution",
                    type: "link",
                    icon: "fact_check",
                    route: [...teamRoutePrefix, "pages", "team-execution"],
                    show: isAnnualPlanningEnabled(company),
                },
                {
                    id: "quarterly-planning-company-execution",
                    name: "executionPlanReportScreen.companyExecution",
                    type: "link",
                    icon: "assignment_turned_in",
                    route: [...teamRoutePrefix, "pages", "company-execution"],
                    show: !!company && company.canManage &&
                        isAnnualPlanningEnabled(company) &&
                        isCrossTeamFeaturesEnabled(company) &&
                        isAdmin && canAccessPage(user, "quarterlyplanning")
                },
            ]
        }
    ];
    const setupSection: IMenuItem[] = [
        {
            id: "setup",
            route: [...teamRoutePrefix, "setup"],
            type: "link",
            name: "Setup",
            icon: "engineering",
            routeMatchOptions: CHILD_ROUTE_MATCH,
            show: isAdmin && canAccessPage(user, "setup")
        },
        {
            id: "clients",
            route: "/pages/clientInstanceList",
            type: "link",
            name: "Clients",
            icon: "supervised_user_circle",
            show: isPartnerAdmin && canAccessPage(user, "clientInstanceList")
        },
        {
            id: "getting-started",
            route: "/help/getting-started",
            type: "link",
            name: "Getting Started",
            icon: "wf-getting-started",
            isSvgIcon: true,
            show: true
        },
        {
            id: "help",
            route: "/help",
            type: "link",
            name: "Help",
            icon: "help_outline",
            show: true
        }
    ];
    return [
        ...homeSection,
        {
            type: "separator",
            show: watchlistSection.some(s => s.show)
        },
        ...watchlistSection,
        {
            type: "separator",
            show: teamPerformanceSection.some(s => s.show)
        },
        ...teamPerformanceSection,
        {
            type: "separator",
            show: setupSection.some(s => s.show)
        },
        ...setupSection
    ];
};
