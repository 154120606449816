<div class="chart-layout">
        
    <button mat-icon-button [disabled]="isLoading" (click)="previous()" matTooltip="{{ 'numbers.chart.includePrev' | translate }}" matTooltipClass="custom-tooltip">
        <mat-icon>chevron_left</mat-icon>
    </button>

    <div class="chart-container">
        <canvas #chart></canvas>
    </div>

    <button mat-icon-button [disabled]="isLoading" (click)="next()"
        matTooltip="{{ 'numbers.chart.includeNext' | translate }}" matTooltipClass="custom-tooltip">
        <mat-icon>chevron_right</mat-icon>
    </button>
</div>
