<label class="year-label" [for]="elementId + '-selected'">{{ 'periodSelector.year' | translate }}</label>

<button type="button" mat-icon-button color="primary"
    (click)="decrementYear()"
    [title]="'periodSelector.prev' | translate">
    <mat-icon>arrow_back</mat-icon>
</button>

<span class="year" [id]="elementId + '-selected'">
    {{ selectedYear }}
</span>

<button type="button" mat-icon-button color="primary"
    (click)="incrementYear()"
    [title]="'periodSelector.next' | translate">
    <mat-icon>arrow_forward</mat-icon>
</button>

<button *ngIf="canReset" type="button" mat-icon-button (click)="reset()" class="reset-button" @fadeIn
    [matTooltip]="'periodSelector.resetToThisYear' | translate" matTooltipClass="custom-tooltip">
    <mat-icon>undo</mat-icon>
</button>