import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NumberRecordDetailDto, SimpleCompanyTeamDto, SimpleUserDto } from "@api";
import { Subscription } from "rxjs";

import { NumberHomepageDialogComponent } from "~homepage";
import { TeamContext } from "~services/contexts";
import { buildRecordMergeHandler, NumberReference, NumberStateEvent, NumberStateService } from "~services/state";
import { CaptureMethod } from "~shared/enums";
import { numberDescriptionSortAccessor } from "~shared/util/number-helper";
import { defaultNumbersFilterPredicate } from "~shared/util/table-filtering";
import { getUserName } from "~shared/util/user-helper";

import { NumberChartDialogComponent } from "../number-chart-dialog/number-chart-dialog.component";

export interface ViewNumbersDialogData {
    team: SimpleCompanyTeamDto;
    numbers: NumberRecordDetailDto[];
    financialYear: number;
    quarter: number;
    week: number;
}

@Component({
    selector: "app-view-numbers-dialog",
    templateUrl: "./view-numbers-dialog.component.html",
    styleUrls: ["./view-numbers-dialog.component.scss"],
    host: {
        class: "wf-theme",
    },
})
export class ViewNumbersDialogComponent implements OnInit, OnDestroy {

    @ViewChild(MatSort, { static: true }) set sort(value: MatSort | null) {
        this.dataSource.sort = value;
    }

    get sort(): MatSort | null {
        return this.dataSource.sort;
    }

    get calculationEnabled(): boolean {
        return this.teamContext.features.calculatedNumbersEnabled();
    }

    get dailyUpdatesEnabled() {
        return this.teamContext.features.dailyUpdatedNumbersEnabled();
    }

    readonly dataSource = new MatTableDataSource<NumberRecordDetailDto>;
    readonly columns = ["trend", "description", "owner", "updater", "result", "target", "options"];

    readonly team: SimpleCompanyTeamDto;
    readonly financialYear: number;
    readonly quarter: number;
    readonly week: number;

    readonly getUserName = getUserName;

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly numberStateService: NumberStateService,
        private readonly teamContext: TeamContext,
        private readonly dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) data: ViewNumbersDialogData,
    ) {
        this.dataSource.data = data.numbers;
        this.team = data.team;
        this.financialYear = data.financialYear;
        this.quarter = data.quarter;
        this.week = data.week;

        this.dataSource.filterPredicate = defaultNumbersFilterPredicate;

        this.dataSource.sortingDataAccessor = (number, property) => {
            switch (property) {
                case "description":
                    return numberDescriptionSortAccessor(number);
                case "owner":
                    return this.getUserName(number.owner);
                case "updater":
                    return this.getUserName(number.updater);
                case "result":
                    return number.result ?? Number.NEGATIVE_INFINITY;
                case "target":
                    return number.recordTarget?.lowerBound ?? number.recordTarget?.upperBound ?? Number.NEGATIVE_INFINITY;
                default:
                    return (number as never)[property] ?? "";
            }
        };
    }

    static open(dialog: MatDialog, data: ViewNumbersDialogData) {
        return dialog.open(ViewNumbersDialogComponent, {
            maxWidth: "1250px",
            data,
            autoFocus: "first-heading",
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(this.numberStateService.eventsForNumbers(...this.dataSource.data).subscribe(this.handleStateEvent));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    applyOwnerFilter = (user: SimpleUserDto | null) => this.dataSource.filter = user?.userId ?? "";

    showNumberGraph = (number: NumberRecordDetailDto) => {
        NumberChartDialogComponent.open(this.dialog, number);
    };

    viewNumber = (number: NumberRecordDetailDto, focusFeed = false) =>
        NumberHomepageDialogComponent.open(this.dialog, number, { focusFeed });

    openFeed = (number: NumberRecordDetailDto) => this.viewNumber(number, /* focusFeed: */ true);

    isCalculated = (number: NumberRecordDetailDto) => number.captureMethod === CaptureMethod.calculated;

    allowsUpdater = (number: NumberRecordDetailDto) => number.captureMethod === CaptureMethod.manual;

    private handleStateEvent = (event: NumberStateEvent) => {
        const data = this.dataSource.data;
        const mergeHandler = buildRecordMergeHandler<NumberRecordDetailDto, NumberReference>();
        const newData = mergeHandler(data, event);
        if (newData) this.dataSource.data = newData;
    };
}
