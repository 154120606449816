<mat-checkbox [formControl]="updatedControl" *ngIf="isExternallyUpdated; else addButton">
    {{ 'reports.updated' | translate }}
</mat-checkbox>

<ng-template #addButton>
    <button mat-flat-button color="primary" (click)="showReports()" class="add-report-button" [appDisable]="disabled">{{ 'reports.update.addReport' | translate }}</button>
</ng-template>

<button mat-icon-button *ngIf="statusNotesEnabled" [appDisable]="disabled" [disabled]="disabled"
    (click)="showReports()" [color]="hasNote ? 'primary' : undefined"
    [matTooltip]="'reports.update.addNote' | translate" matTooltipClass="custom-tooltip" class="add-notes-button"
    [attr.aria-pressed]="hasNote">
    <mat-icon>record_voice_over</mat-icon>
</button>