<form [formGroup]="form" (submit)="save()">
    <h4 mat-dialog-title *ngIf="form.enabled && isNewNewsItem">{{'news.createNewsItem' | translate}}</h4>
    <h4 mat-dialog-title *ngIf="form.enabled && !isNewNewsItem">{{'news.editNewsItem' | translate}}</h4>
    <h4 mat-dialog-title *ngIf="!form.enabled">{{'news.viewNewsItem' | translate}}</h4>
    <mat-dialog-content>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label>{{'news.heading' | translate}}</mat-label>
                    <textarea matInput name="heading" formControlName="heading" cdkTextareaAutosize
                        cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" autocomplete="off" maxlength="250"
                        required></textarea>
                    <mat-error *ngIf="headingControl.hasError('required')">
                        {{'news.headingRequired' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label>{{'news.description' | translate}}</mat-label>
                    <textarea matInput name="description" formControlName="description" cdkTextareaAutosize
                        cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" autocomplete="off" maxlength="1000"
                        required></textarea>
                    <mat-error *ngIf="descriptionControl.hasError('required')">
                        {{'news.descriptionRequired' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <fieldset *ngIf="form.enabled || links.length">
            <legend>{{'news.links' | translate}}</legend>
            <div class="row">
                <div class="col-sm-12">
                    <div *ngFor="let link of links; let index = index;" class="item-container" @fadeExpand
                        [@.disabled]="disableAnimations">
                        <div class="link">
                            <i class="fas fa-link"></i>
                            <a class="link-name" [attr.href]="link" target="_blank">{{ link }}</a>
                            <i class="fas fa-minus-circle" (click)="removeLink(index)" *ngIf="form.enabled"></i>
                        </div>
                    </div>
                    <form (submit)="addLink(); $event.preventDefault();" *ngIf="form.enabled">
                        <mat-form-field class="link-field" subscriptSizing="dynamic">
                            <mat-label>{{ 'news.addLink' | translate }}</mat-label>
                            <input matInput type="url" name="link" [formControl]="linkControl" autocomplete="off"
                                maxlength="600" placeholder="https://example.com" />
                            <button mat-icon-button matSuffix type="submit" color="primary"
                                [appMatVisualDisable]="!linkControl.value" [matTooltip]="'Done' | translate"
                                matTooltipClass="custom-tooltip">
                                <mat-icon>done</mat-icon>
                            </button>
                        </mat-form-field>
                    </form>

                </div>
            </div>
        </fieldset>

        <fieldset *ngIf="form.enabled || attachments.length">
            <legend>{{'news.attachments' | translate}}</legend>
            <div class="row">
                <div class="col-sm-12">
                    <div *ngFor="let doc of attachments" class="item-container" @fadeExpand
                        [@.disabled]="disableAnimations">
                        <div class="attachment">
                            <i class="fas fa-file-alt"></i>
                            <a class="attachment-name" [attr.href]="doc.uri" target="_blank">{{ doc.name }}</a>
                            <i class="fas fa-minus-circle" *ngIf="form.enabled"
                                (click)="removeExistingAttachment(doc)"></i>
                        </div>
                    </div>
                    <div *ngFor="let doc of newAttachments" class="item-container" @fadeExpand
                        [@.disabled]="disableAnimations">
                        <div class="attachment">
                            <i class="fas fa-file-alt"></i>
                            <span class="attachment-name">{{ doc.name }}</span>
                            <i class="fas fa-minus-circle" (click)="removeNewAttachment(doc)"></i>
                        </div>
                    </div>
                    <div class="button-row" *ngIf="form.enabled">
                        <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)"
                            [accept]="attachmentAcceptTypes" />
                        <button type="button" mat-button color="primary" (click)="fileInput.click()">
                            {{ "news.uploadAttachment" | translate }} <mat-icon>upload</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </fieldset>

        <div class="row">
            <div class="col-sm-12">
                <ng-container *ngIf="form.enabled; else fakeTeams">
                    <mat-form-field *ngIf="companies$ | async; let companies">
                        <mat-label>{{ 'news.teams' | translate }}</mat-label>
                        <mat-select disableOptionCentering name="recipients" formControlName="recipients" required
                            multiple [compareWith]="compareTeams">
                            <mat-select-trigger>
                                <mat-chip-listbox>
                                    <mat-chip-option *ngFor="let team of recipientsControl.value" [removable]="true"
                                        (removed)="removeTeam(team)">
                                        {{ team.name }}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip-option>
                                </mat-chip-listbox>
                            </mat-select-trigger>
                            <div class="teams-panel">
                                <span class="all-teams-option">
                                    <mat-checkbox (change)="selectAllTeams($event.checked, companies)"
                                        [checked]="areAllTeamsSelected(companies)"
                                        [indeterminate]="areSomeTeamsSelected(companies) && !areAllTeamsSelected(companies)">
                                        {{ 'news.allTeams' | translate }}
                                    </mat-checkbox>
                                </span>
                                <mat-option style="display:none"></mat-option>
                                <ng-container *ngIf="companies.length === 1">
                                    <mat-option *ngFor="let team of companies[0].teams" [value]="team">
                                        {{ team.name }}
                                    </mat-option>
                                </ng-container>
                                <ng-container *ngIf="companies.length !== 1">
                                    <mat-optgroup *ngFor="let company of companies">
                                        <div class="checkbox-container">
                                            <mat-checkbox (change)="selectAllCompanyTeams(company, $event.checked)"
                                                [checked]="areAllCompanyTeamsSelected(company)"
                                                [indeterminate]="areSomeCompanyTeamsSelected(company) && !areAllCompanyTeamsSelected(company)">
                                                {{ company.company.name }}
                                            </mat-checkbox>
                                        </div>
                                        <mat-option *ngFor="let team of company.teams" [value]="team">
                                            {{ team.name }}
                                        </mat-option>
                                    </mat-optgroup>
                                </ng-container>
                            </div>
                        </mat-select>
                        <mat-error *ngIf="recipientsControl.hasError('required')">
                            {{'news.teamsRequired' | translate}}
                        </mat-error>
                    </mat-form-field>
                </ng-container>
                <ng-template #fakeTeams>
                    <mat-form-field [floatLabel]="recipientsControl.value.length ? 'always' : 'auto'">
                        <mat-label>{{ 'news.teams' | translate }}</mat-label>
                        <input type="text" matInput disabled class="hidden" />
                        <mat-chip-listbox disabled required>
                            <mat-chip-option *ngFor="let team of recipientsControl.value">
                                {{ team.name }}
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </mat-form-field>
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 bottom-margin">
                <mat-form-field *ngIf="creatorControl.enabled; else fakeCreator">
                    <mat-label>{{'news.creator' | translate}}</mat-label>
                    <app-auto-select name="creator" formControlName="creator" [options]="users$ | async"
                        [optionValueFunc]="getUserId" [optionDisplayFunc]="getUserName" required>
                    </app-auto-select>
                    <mat-error *ngIf="creatorControl.hasError('required')">
                        {{'news.creatorRequired' | translate}}
                    </mat-error>
                </mat-form-field>
                <ng-template #fakeCreator>
                    <mat-form-field>
                        <mat-label>{{'news.creator' | translate}}</mat-label>
                        <input matInput type="text" [value]="getUserName(originalCreator)" disabled required />
                    </mat-form-field>
                </ng-template>
            </div>
        </div>
        
        <div class="row" *ngIf="form.hasError('capReached')">
            <div class="col-xs-12" role="alert">
                <p class="warning-box" translate="news.capReached"></p>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end" *ngIf="!form.disabled">
        <app-status-button type="submit" [state]="buttonState" [appMatVisualDisable]="!form.valid">
            {{ "Save" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>