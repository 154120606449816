import { Component } from "@angular/core";

import { UserContext } from "~services/contexts";

@Component({
    selector: "app-plan-upgrade-button",
    templateUrl: "./plan-upgrade-button.component.html",
    styleUrls: ["./plan-upgrade-button.component.scss"],
    host: {
        class: "wf-theme"
    }
})
export class PlanUpgradeButtonComponent {

    readonly isBillingAdmin = this.userContext.isBillingAdmin;

    constructor(
        private readonly userContext: UserContext
    ) { }

}
