<mat-button-toggle-group [value]="type" (valueChange)="selectType($event)" class="icon-toggle-group">
    <mat-button-toggle value="comment" [matTooltip]="'FeedsList.tooltips.newComment' | translate" matTooltipClass="custom-tooltip">
        <mat-icon color="primary">add_comment</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="link" [matTooltip]="'FeedsList.tooltips.newLink' | translate" matTooltipClass="custom-tooltip">
        <mat-icon color="primary">add_link</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="attachment" [matTooltip]="'FeedsList.tooltips.newAttachment' | translate" matTooltipClass="custom-tooltip">
        <mat-icon color="primary">upload_file</mat-icon>
    </mat-button-toggle>
</mat-button-toggle-group>

<input #filePicker type="file" style="display: none;" (change)="uploadAttachment()"
    [accept]="acceptTypes" />