<button mat-button [matMenuTriggerFor]="menu" (menuOpened)="form.reset(); focusFilter()"
    class="team-select-button">
    <div class="team-select-button-layout">
        <div class="team-name">{{ name?.teamName ?? ('header.noTeam' | translate) }}</div>
        <div class="company-name">{{ name?.companyName ?? "" }}</div>
        <mat-icon>arrow_drop_down</mat-icon>
    </div>
</button>

<mat-menu #menu class="main_menu">
    <div class="team-filter-container wf-theme" *ngIf="showFilter$ | async"
        (click)="$event.stopPropagation()" (keydown)="handleFilterKeydown($event)" role="none">
        <mat-form-field class="no-label" subscriptSizing="dynamic">
            <input type="text" matInput [formControl]="filterControl"
                name="teamFilter" autocomplete="off"
                [placeholder]="'header.filterTeams' | translate" #filterInput />
            <button mat-icon-button matSuffix *ngIf="filterControl.value"
                (click)="filterControl.setValue(null)">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <ng-container *ngIf="filteredTeams$ | async; let filteredTeams">
        <a *ngFor="let team of filteredTeams" mat-menu-item (click)="changeCompanyTeamName(team)"
            [routerLink]="['/company', team.company.id, 'team', team.team.id, 'dashboard']">
            <div class="team-select-layout">
                <div class="team-name">{{ team.team.name }}</div>
                <div class="company-name">
                    {{ team.company.name }}
                    <ng-container class="name-override" *ngIf="team.company.adminNameOverride">({{ team.company.adminNameOverride }})</ng-container>
                </div>
            </div>
        </a>
        <div *ngIf="!filteredTeams.length" class="no-teams-message">
            {{ 'header.noTeamsFound' | translate }}
        </div>
    </ng-container>
    <button mat-menu-item class="show-more-button" *ngIf="!showAllClientsControl.value && (showShowAllClients$ | async)"
        (click)="showAllClientsControl.setValue(true); $event.stopPropagation()">
        {{ 'header.showMore' | translate }}
    </button>
</mat-menu>