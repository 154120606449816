<app-spinner *ngIf="!hasTeam()"></app-spinner>

<div class="main-container" *ngIf="hasTeam()">
    <mat-toolbar class="wf-theme wf-theme-dark">
        <div class="navbar-header">
            <button mat-icon-button (click)="toggleMenu()">
                 <mat-icon>menu</mat-icon>
            </button>
            <img class="main-logo" src="assets/images/WorkfactaLogoTagline.svg" [alt]="'Workfacta' | translate" />
            <img class="main-logo-medium" src="assets/images/WorkfactaLogoLight.svg" [alt]="'Workfacta' | translate" />
            <img class="main-logo-small" src="assets/images/wf-logo.svg" [alt]="'Workfacta' | translate" />
        </div>
        <header>
            <img class="company-logo" [src]="companyLogoUrl()" *ngIf="!sidebarTeamSelect && !mobileHeader && companyLogoVisible()" [alt]="'header.companyLogo' | translate" />
            <app-team-select-menu *ngIf="!sidebarTeamSelect"></app-team-select-menu>
            <app-global-create-button *ngIf="showGlobalCreateButton()" [size]="mobileHeader ? 'small' : 'large'"></app-global-create-button>

            <div class="spacer"></div>

            <mat-spinner id="loading-spinner" [diameter]="mobileHeader ? 40 : 50" [title]="'statusButton.loading' | translate"></mat-spinner>
            <app-user-menu [size]="mobileHeader ? 'small' : 'large'"></app-user-menu>
        </header>
    </mat-toolbar>

    <mat-sidenav-container class="example-sidenav-container" [hasBackdrop]="sidebarMode === 'over'">

        <mat-sidenav #snav id="snav" class="dark-sidebar wf-theme wf-theme-dark" [mode]="sidebarMode" fixedTopGap="0" [opened]="sidebarMode === 'side'" [disableClose]="sidebarMode === 'side'">
            <div class="sidebar-company-header" *ngIf="sidebarTeamSelect">
                <img class="company-logo" [src]="companyLogoUrl()" *ngIf="companyLogoVisible()" [alt]="'header.companyLogo' | translate" />
                <app-team-select-menu></app-team-select-menu>
            </div>
            <app-sidebar></app-sidebar>
        </mat-sidenav>

        <mat-sidenav-content class="page-wrapper">

            <div class="page-content">

                <router-outlet></router-outlet>

            </div>

        </mat-sidenav-content>
    </mat-sidenav-container>
</div>