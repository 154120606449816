<div class="posted-text">
    <b class="name" [class.user-deleted]="!feedItem.user">
        <ng-container *ngIf="!!feedItem.user; else noUser">
            {{ getUserName(feedItem.user) }}
        </ng-container>
        <ng-template #noUser>
            {{ 'FeedsList.listItems.deletedUser' | translate }}
        </ng-template>
    </b> - 
    <span [matTooltip]="feedItem.createdDate | dateTimeFormat" matTooltipClass="custom-tooltip">
        {{ feedItem.createdDate | timeago: true }}
    </span>
    <span class="edited-tag" *ngIf="isCommentEdited">
        {{ 'FeedsList.listItems.comments.edited' | translate }}
    </span>
</div>